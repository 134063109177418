import { PURPLE } from "#app/colors/colors"
import { getSupportArticleMenuItems, SupportMenuItem, updateMultipleOrderPriorities } from "#app/services/payloadCMS/payloadCMS"
import useCurrentCountryCode from "#app/services/useCurrentCountryCode"
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"
import { SortableItem } from "../SortableItem"
import { useIsLoggedInToPayload } from "../hooks/useIsLoggedInToPayload"
import { useQuery } from "react-query"

const SupportMainMenu = () => {
    const { t } = useTranslation()
    const country = useCurrentCountryCode()
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedLanguage = country === "SE" ? "sv_SE" : "nb_NO"
    const [menuItemIds, setMenuItemIds] = useState<string[]>([])
    const loggedInToPaylod = useIsLoggedInToPayload()
    const { data: supportArticleMenuItems } = useQuery({
        queryFn: async () => await getSupportArticleMenuItems(selectedLanguage, country),
        queryKey: ['supportArticleMenuItems', selectedLanguage, country],
        placeholderData: [],
        onSuccess: (data) => {
            if (data) {
                setMenuItemIds([...data].sort((a, b) => a.orderPriority - b.orderPriority)?.map(item => item.articleId))
            }
        },
    })

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    )


    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            setMenuItemIds((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                const newArr = arrayMove(items, oldIndex, newIndex)
                updateMultipleOrderPriorities(country, newArr)

                return arrayMove(items, oldIndex, newIndex);
            })
        }
    }

    return (
        <Stack spacing={1}>
            <Stack spacing={1}>
                <Typography variant="h3">{t('layers-SupportPane.needHelp')}</Typography>
                <Typography variant="body2">{t('layers-SupportPane.helpIntroduction')}</Typography>
            </Stack>
            { loggedInToPaylod ? (
                <List>
                    <DndContext      
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}>
                        <SortableContext 
                            items={menuItemIds}
                            strategy={verticalListSortingStrategy}>
                            { [...supportArticleMenuItems]?.sort((a, b) => menuItemIds.indexOf(a.articleId) - menuItemIds.indexOf(b.articleId))?.map((item) => (
                                <SortableItem id={item.articleId} key={item.articleId}>
                                    <ListItemButton 
                                        sx={{color: PURPLE[500]}} 
                                        color="primary"
                                        onClick={() => setSearchParams({supportId: item.articleId})}>
                                        <ListItemText primary={item.label} />
                                        <ListItemIcon sx={{backgroundColor: "transparent"}}>
                                            <i className="ri-arrow-right-line"/>
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <Divider />
                                </SortableItem>
                            ))}
                        </SortableContext>
                    </DndContext>
                </List>
            ) : (
                <List>
                    { [...supportArticleMenuItems]?.sort((a, b) => a.orderPriority - b.orderPriority)?.map((item) => (
                        <Fragment key={item.articleId}>
                            <ListItemButton 
                                sx={{color: PURPLE[500]}} 
                                color="primary"
                                onClick={() => setSearchParams({supportId: item.articleId})}>
                                <ListItemText primary={item.label} />
                                <ListItemIcon sx={{backgroundColor: "transparent"}}>
                                    <i className="ri-arrow-right-line"/>
                                </ListItemIcon>
                            </ListItemButton>
                            <Divider />
                        </Fragment>
                    ))}
                </List>
            )}
        </Stack>
    )
}

export { SupportMainMenu }