import useCurrentDepositor from '#app/services/useCurrentDepositor';
import { OrganisationalPersonDto, PersonMatchDto } from '@fixrate/fixrate-query';
import { useCommand } from '#command';
import Modal from '#components/Modal';
import { useFieldState } from '@fixrate/fieldstate';
import { Box, Button, FormLabel, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    close: () => void,
    person: PersonMatchDto,
    fixratePerson: OrganisationalPersonDto,
}

function formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('no-NB');
}

const BrregModifyPerson = function ({ close, person, fixratePerson }: Props) {

    const { t } = useTranslation();
    const depositor = useCurrentDepositor();
    const { updatePersonInfo } = useCommand();

    const firstName = useFieldState(
        fixratePerson.firstName,
        ({ value }) => {
            if (!value) {
                return t('pages-organizations.newPersonFirstNameMissing');
            }
        },
    );

    const lastName = useFieldState(
        fixratePerson.lastName,
        ({ value }) => {
            if (!value) {
                return t('pages-organizations.newPersonLastNameMissing');
            }
        },
    );

    async function submit() {
        const isValid = firstName.validate() && lastName.validate();

        if (!isValid) return;

        try {
            const { waitForCommand, rejected } = await updatePersonInfo({
                depositorId: depositor.id,
                personId: person.personId,
                firstName: firstName.value,
                lastName: lastName.value,
                birthDate: fixratePerson.birthDate,
                email: fixratePerson.email,
                address: fixratePerson.address,
                keyPersonRole: fixratePerson.keyPersonRole
            });

            if (rejected) {
                closeModal();
            } else {
                await waitForCommand();
                closeModal();
            }

        } catch (err) {
            console.error(err);
        }
    }

    const closeModal = () => {
        close();
    }

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1;
        return field + '-' + randomNumber;
    }

    const formattedBirthDate = formatDate(person.birthDate);

    function updateName(): void {
        const nameParts = person.proffName.split(' ');
        const initialLastName = nameParts.pop();
        const initialFirstName = nameParts.join(' ');
        firstName.setValue(initialFirstName)
        lastName.setValue(initialLastName)
    }

    return (
        <Modal
            header={t('pages-organizations.editPersonName')}
            onSubmit={submit}
            submitButtonId={'editPersonNameConfirm'}
            onCancel={closeModal}
            width="50rem"
            submitButtonText={t('common.confirm')}
        >
            <form autoComplete="off">
                <Stack spacing={1.5}>
                    <Box>
                        <Typography variant="body1">{t('pages-organizations.nameBrreg')}:</Typography>
                        <Typography variant="body2">{person.proffName}</Typography>
                        <Typography variant="body1">{t('pages-organizations.personBirthDate')}:</Typography>
                        <Typography variant="body2">{formattedBirthDate}</Typography>
                    </Box>

                    <Box>
                        <FormLabel htmlFor="firstNameField">
                            {t('pages-organizations.newPersonFirstName')}
                        </FormLabel>

                        <TextField
                            id="firstNameField"
                            name={autoCompleteBlocker('fixrate-fname')}
                            onBlur={firstName.onBlur}
                            onChange={e => firstName.setValue(e.target.value)}
                            autoComplete="off"
                            value={firstName.value}
                        />
                        <p className="field-error-message">
                            {firstName.errorMessage}
                        </p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="lastNameField">
                            {t('pages-organizations.newPersonLastName')}
                        </FormLabel>
                        <TextField
                            id="lastNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={lastName.onBlur}
                            onChange={e => lastName.setValue(e.target.value)}
                            type="text"
                            autoComplete="off"
                            value={lastName.value}
                        />
                        <p className="field-error-message">
                            {lastName.errorMessage}
                        </p>

                    </Box>
                    <Box>
                        <Tooltip title={t('pages-organizations.updatePersonNameTooltip')}>
                            <Button variant="outlined" onClick={updateName} data-cy="updatePerson">
                                {t('pages-organizations.updatePersonName')}
                            </Button>
                        </Tooltip>
                    </Box>
                </Stack>
            </form>
        </Modal>
    );
}

export default BrregModifyPerson;