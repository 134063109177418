import { Alert } from '@mui/material';
import styles from './BrregBox.module.scss'
import { useTranslation } from 'react-i18next'
import classNames from "classnames";
import { Dispatch, SetStateAction } from 'react';
import { SUNSET_ORANGE } from '#app/colors/colors';

interface Props {
    header: string,
    infoVisible: boolean,
    setInfoVisible: Dispatch<SetStateAction<boolean>>,
    hasPeopleChanges: boolean,
    hasNonExactMatch: boolean,
    children: React.ReactNode,
}

export default function Brregbox({ header, infoVisible = true, setInfoVisible, hasPeopleChanges, hasNonExactMatch, children }: Props) {
    const { t } = useTranslation()
    return (
        <div className={styles.changes}>
            <div className={classNames(styles.content, infoVisible ? styles.openContent : styles.closedContent)}>
                <div
                    className={classNames(styles.header, styles.openHeader)}
                    onClick={() => setInfoVisible(!infoVisible)}
                >
                    <p>
                        <i className="ri-archive-drawer-line" />
                        <span>{header}</span>
                    </p>
                    {hasPeopleChanges && hasNonExactMatch &&
                        <Alert
                            variant='filled'
                            data-cy="brreg-changes"
                            icon={<i className="ri-information-line" />}
                            sx={{
                                p: '0rem 0.5rem',
                                backgroundColor: SUNSET_ORANGE[50],
                                color: SUNSET_ORANGE[900],
                                fontVariant: 'normal',
                                marginLeft: 'auto',
                                marginRight: '1rem'
                            }}
                        >
                            {t('pages-organizations.personGalleryMismatch')}
                        </Alert>
                    }
                    <i className={classNames(styles.closeOpenIcon, infoVisible ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line")} />
                </div>

                {infoVisible && children}
            </div>
        </div>
    )
}