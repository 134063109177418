import { PURPLE } from "#app/colors/colors";
import { useLocalStorage } from "#app/services/LocalStorageContext";
import { useSelector } from "#app/state/useSelector";
import { FundCategory } from "@fixrate/fixrate-query";
import {
    Box,
    Button,
    Drawer,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ReactElement, useState} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FilterPanel from "../FilterPanel";
import useFundFilterStorage from "../useFundFilterStorage";

export default function FilterLayout() {
    const {t} = useTranslation()
    const {
        keys: { fundsViewType },
        dispatchLocalStorage,
    } = useLocalStorage()

    const depositor = useSelector(state => state.depositor)
    const funds = useSelector(state => state.funds)
    const filterStorage = useFundFilterStorage()
    const [filterOpen, setFilterOpen] = useState(false)
    const { fundCategory } = useParams() as { fundCategory: FundCategory }
    const filterMainFundCategory = fundCategory.replace(/-/g, '_').toUpperCase()
    const availableFunds = funds
        .filter(f => f.fundShareClasses?.some(fsc => fsc.published || fsc.tradableByDepositorIds?.includes(depositor?.id)))
        .filter(fund => fund.fundCategory === filterMainFundCategory)

    return (
        <Stack
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            direction={{xs: 'column', md: 'row'}}
            alignItems={{xs: 'normal', md: 'center'}}
        >
            <Stack direction="row" spacing={1} sx={{mb: 1}}>
                <Button
                    variant="outlined"
                    startIcon={<i className="ri-filter-line"/>}
                    endIcon={
                        filterStorage.filters.length > 0 ? (
                            <Box display="flex" borderRadius={"20rem"} alignItems={"center"} justifyContent={"center"} width={"2rem"} height={"2rem"} bgcolor={PURPLE[500]}>
                                <Typography fontWeight="600" color={"white"} fontSize={"1.2rem"}>{filterStorage.filters.length}</Typography>
                            </Box>
                        ) : null
                    }
                    size="small"
                    data-cy="fund-filter-button"
                    onClick={() => setFilterOpen(v => !v)}>
                    {t('pages.fundMarketplace.filter')}
                </Button>
                <FilterDrawer open={filterOpen} setOpen={setFilterOpen}>
                    <FilterPanel funds={availableFunds} filterStorage={filterStorage} onClose={() => setFilterOpen(false)}/>
                </FilterDrawer>
                <ToggleButtonGroup
                    sx={{
                        border: 0,
                        backgroundColor: "transparent",
                        "& .MuiToggleButtonGroup-grouped": {
                            border: "0.1rem solid",
                            m: 0,
                            "&:last-of-type": {
                                borderRadius: "0 0.2rem 0.2rem 0"
                            },
                            "&:first-of-type": {
                                borderRadius: "0.2rem 0 0 0.2rem"
                            }
                        },
                        "& .MuiToggleButton-root i": {
                            fontWeight: "normal",
                            fontSize: "1.6rem",
                            lineHeight: "1",
                            margin: 0
                        }
                    }}
                    size="small"
                    color="primary"
                    value={fundsViewType}>
                    <ToggleButton data-cy="view-selector-grid" value={'GRID'} onClick={() => dispatchLocalStorage({type: "FUNDS_VIEW_TYPE", payload: "GRID"})}>
                        <i className="ri-grid-line"/>
                    </ToggleButton>
                    <ToggleButton data-cy="view-selector-list" value={'LIST'} onClick={() => dispatchLocalStorage({type: "FUNDS_VIEW_TYPE", payload: "LIST"})}>
                        <i className="ri-menu-line"/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
        </Stack>
    )
}

function FilterDrawer({open, setOpen, children}: {open: boolean, setOpen: (open: boolean) => void, children: ReactElement}) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Drawer
            open={open}
            onClose={() => setOpen(false)}
            anchor={isMobile ? 'bottom' : 'right'}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '100%',
                    maxHeight: isMobile ? '80%' : '100%',
                },
            }}
        >
            {children}
        </Drawer>
    )
}
