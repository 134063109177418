import { useSelector } from '#app/state/useSelector'
import {DepositorPartnerProposalDto, DepositorUserDto, DocumentDto, PartnerRelationDto} from '@fixrate/fixrate-query'

type PartnerInformation = {
    authorizationDocument: DocumentDto | undefined
    partnerUser: DepositorUserDto | undefined
    selectedPartner: PartnerRelationDto,
    selectedProposal: DepositorPartnerProposalDto | undefined
}
export default function usePartnerInformation(partnerId?: string, proposalId?: string) : PartnerInformation {
    const partnerRelations = useSelector(state => state.depositor?.partnerRelations)
    const depositorUsers = useSelector(state => state.depositor?.users)
    const documents = useSelector(state => state.documents)
    // If partnerId is not provided, return the first partner found which in most cases is the only partner
    const selectedPartner = partnerRelations?.find(relation => partnerId ? relation.partnerId === partnerId : true)

    const selectedProposal = selectedPartner?.partnerProposals.find(proposal => proposal.proposalId === proposalId)

    const partnerUser = depositorUsers
        ?.filter(user => selectedPartner?.advisors
            ?.find(u => u.userId === user.id))
            // If proposal is provided, return the user that sent the proposal, otherwise return the first user with the corred roles
            ?.find(user => selectedProposal
                ? selectedProposal.userId === user.id
                : (user.roles.includes('DEPOSITOR_PARTNER_ADVISOR') || user.roles.includes('DEPOSITOR_PARTNER_ACCOUNTANT')) && user.roles.includes("DEPOSITOR_ACCOUNT_HOLDER"))
    const authorizationDocument = Object.keys(documents).map(key => documents[key]).find(doc => doc.documentType === 'AUTHORIZATION_DOCUMENT' && doc.id === partnerUser?.authorizationDocumentId)

    return {
        authorizationDocument,
        partnerUser,
        selectedPartner,
        selectedProposal
    }
}
