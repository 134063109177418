import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { Badge, Box, Divider, MenuItem as MUIMenuItem, MenuList, Stack, SxProps } from '@mui/material'
import classNames from 'classnames'
import {ReactChild, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
    id: string,
    remixIcon: string,
    name: string,
    link?: string,
    externalLink?: boolean,
    onClick?: () => void,
    counter?: number,
    children?: ReactChild | ReactChild[] | null,
    darkMode?: boolean,
    innerElement?: ReactChild | null,
}

// Router aware link wrapper
const MenuItem = ({
    id,
    remixIcon,
    name,
    link = undefined,
    externalLink = false,
    onClick = undefined,
    counter = undefined,
    children = null,
    darkMode = false,
    innerElement = null,
}: Props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const isActive = useMemo(() => location.pathname.startsWith(link), [link, location.pathname])

    const [hover, setHover] = useState(false)

    const handleClick = () => {
        onClick?.()

        if (link && externalLink) {
            // Some browsers (e.g. Safari on iOS) are restrictive on opening new tabs.
            // Try to open link in a new tab and fall back on a normal top level redirect.
            const newTab = window.open(link, '_blank')
            if (!newTab) {
                window.location.href = link
            }
        }
        if (link && !externalLink) {
            navigate(link)
        }
    }

    const MenuItemColor = darkMode ? {
        backgroundColor: isActive ? "rgba(250,250,250,0.1)" : PURPLE[900],
        '&:hover': { backgroundColor: isActive ? "rgba(250,250,250,0.1)" : "rgba(250,250,250,0.05)" },
        '&:focus': { backgroundColor: "rgba(250,250,250,0.1)"},
        borderColor: isActive ? SPRING_GREEN[500] : 'transparent',
        color: isActive ? PURPLE[25] : PURPLE[50]
    } : {
        backgroundColor: isActive ? PURPLE[50] + '80' : '#fff',
        '&:hover': { backgroundColor: isActive ? PURPLE[50] + '80' : '#fff' },
        '&:focus': { backgroundColor: PURPLE[50]},
        borderColor: isActive ? PURPLE[500] : 'transparent',
        color: PURPLE[900]
    }

    return (
        <Stack component="li" my={0}>
            <MUIMenuItem
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                id={id}
                onClick={handleClick}
                tabIndex={0}
                component="div"
                sx={{
                    borderLeft: '0.4rem solid transparent',
                    alignItems: innerElement ? 'flex-start' : 'center',
                    flexDirection: innerElement ? 'column' : 'row',
                    py: 1.8,
                    ...MenuItemColor
                }}>
                <Stack direction="row" alignItems="center">
                    <Badge badgeContent={counter} color={'error'}>
                        <i className={classNames((isActive || hover) ? remixIcon.replace(/-line$/, `-fill`) : remixIcon)}></i>
                    </Badge>
                    <Box sx={{ml: 1, fontWeight: '600'}}>{name}</Box>
                </Stack>
                {innerElement}
            </MUIMenuItem>
            <Box>
                <Divider sx={{my: 0, borderBottom: darkMode ? "0.1rem solid rgba(250,250,250,0.2)" : undefined}} />
            </Box>
            {isActive && (
                <MenuList sx={{p: 0}}>
                    {children}
                </MenuList>
            )}
        </Stack>
    );
}

const subNavSx:SxProps = {
    backgroundColor: PURPLE[50] + '80',
    '&:hover': { backgroundColor: PURPLE[50] + '80' },
    '&:focus': { backgroundColor: PURPLE[50]},
    fontSize: '1.2rem',
    alignItems: 'center',
    py: 1
}

// NotActivePath should probably be removed when all the routes for deposits and funds are fixed
// TODO: Remove notActivePath and introduce /deposits and /funds routes

type SubNavProps = {
    to: string,
    children: ReactChild | ReactChild[],
    notActivePath?: string,
    id?: string,
    darkMode?: boolean,
}

function SubNav({to, children, notActivePath = undefined, id = undefined, darkMode}: SubNavProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const isActive = location.pathname.startsWith(to) && !location.pathname.startsWith(notActivePath)
    const MenuItemColor = darkMode ? {
        backgroundColor: isActive ? "rgba(250,250,250,0.1)" : "rgba(250,250,250,0.05)",
        '&:hover': { backgroundColor: isActive ? "rgba(250,250,250,0.1)" : "rgba(250,250,250,0.05)" },
        '&:focus': { backgroundColor: "rgba(250,250,250,0.1)"},
        color: isActive ? PURPLE[25] : PURPLE[50]
    } : {}

    return (
        <Stack>
            <MUIMenuItem tabIndex={0} id={id} onClick={() => navigate(to)} sx={{...subNavSx, fontWeight: isActive ? '700' : '500', ...MenuItemColor }}>
                <Stack width={'100%'}>
                    {children}
                </Stack>
            </MUIMenuItem>
            <Box>
                <Divider sx={{my: 0}} />
            </Box>
        </Stack>
    )
}
MenuItem.SubNav = SubNav

type SubNavOnClickProps = {
    onClick: () => void,
    id?: string,
    isActive?: boolean,
    children: ReactChild | ReactChild[],
    className?: string,
    counter?: number,
}

function SubNavOnClick({onClick, id = undefined, isActive = false, children = '', className = '', counter = 0, ...props}: SubNavOnClickProps) {
    return (
        <Stack>
            <MUIMenuItem tabIndex={0} {...props} id={id} onClick={onClick} sx={{...subNavSx, fontWeight: isActive ? '700' : '500' }}>
                <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box sx={{whiteSpace: 'normal'}}>{ children }</Box>
                    { !!counter && <Badge sx={{mr: 1}} badgeContent={counter} color={'error'}></Badge> }
                </Stack>
            </MUIMenuItem>
            <Box>
                <Divider sx={{my: 0}} />
            </Box>
        </Stack>
    )
}
MenuItem.SubNavOnClick = SubNavOnClick

export default MenuItem
