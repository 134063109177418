import { PURPLE } from '#app/colors/colors'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'
import useSupportPane from '#services/useSupportPane'
import { useSelector } from '#state/useSelector'
import { Box, IconButton } from '@mui/material'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import styles from './HelpPopup.module.scss'

type Props = {
    showAsDefault?: boolean,
    text: string,
    children: React.ReactNode,
    supportCommonName?: SupportCategoryNameType,
    helpContextPage?: string
}

export default function HelpPopup({showAsDefault, children, text, supportCommonName, helpContextPage}: Props) {

    const {t} = useTranslation()
    const [isOpen, setIsOpen] = useState(showAsDefault)
    const supportPane = useSupportPane()
    const session = useSelector(state => state.session)
    const {trackEvent} = useTracking({page: helpContextPage, session})

    const showSupportArticle = () => {
        trackEvent({event: 'open-context-help'})
        if (supportCommonName) {
            supportPane.show(supportCommonName)
        }
    }

    function toggleOpen() {
        setIsOpen(!isOpen)
    }

    const filled = (
        <i className={classNames('ri-information-fill')}></i>
    )
    const unfilled = (
        <i className={classNames('ri-information-line')}></i>
    )

    return (
        <span>
            <span className={styles.title}>
                {children}
                <IconButton
                    color='primary'
                    onClick={toggleOpen}>
                    {isOpen ? filled : unfilled}
                </IconButton>
            </span>
            {isOpen && (
                <Box className={styles.text} sx={{backgroundColor: PURPLE[50], color: PURPLE[900]}}>
                    {text}
                    {supportCommonName &&
                        <span className={classNames(styles.link)} onClick={showSupportArticle}>
                            &nbsp;<b>{t('components-HelpIcon.readMore')}</b>
                        </span>
                    }
                </Box>
            )}
        </span>
    )
}
