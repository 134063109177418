import { getSupportArticle } from '#app/services/payloadCMS/payloadCMS';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import { useQuery } from 'react-query';

function useGetSupportArticle(articleId: string | null) {
    const country = useCurrentCountryCode()
    const language = country === "SE" ? "sv_SE" : "nb_NO"
    const {data: supportArticle} = useQuery({
        queryFn: async () => getSupportArticle({ id: articleId, language, country }),
        queryKey: ['supportArticle', articleId, country],
    })

    return supportArticle || null
}

export { useGetSupportArticle };
