import { Paper } from '#app/components';
import FxDialog from '#app/components/FxDialog/FxDialog';
import FxDialogActions from '#app/components/FxDialog/FxDialogActions';
import FxDialogContent from '#app/components/FxDialog/FxDialogContent';
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import { useCommand } from '#command';
import ButtonRow from '#components/ButtonRow/ButtonRow';
import LabeledInput from '#components/LabeledInput/LabeledInput';
import { SignableDocument } from '#components/SignableDocument/SignableDocument';
import { useAuthorization } from '#services/authorization';
import { formatAccount, formatIban } from '#services/formatnumber';
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import * as selectors from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { DepositDto } from '@fixrate/fixrate-query';
import { Alert, Box, Button, MenuItem, Select } from '@mui/material';
import { Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    deposit: DepositDto,
    toggleChangeSettlementAccount: () => void,
    showChangeSettlementAccount: boolean,
}

export default function DepositChangeSettlementAccount(
    {
        deposit,
        toggleChangeSettlementAccount,
        showChangeSettlementAccount,
    }: Props) {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const organisationCountry = useCurrentCountryCode()

    const settlementAccountValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('pages-portfolio-depositor.settlementAccountChangeSelectDepositAccount'))
    const settlementAccount = useFieldState('', settlementAccountValidator)

    const {
        setSettlementAccount,
        sendSettlementAccountChangeToBank,
        cancelChangeSettlementAccount,
        startElectronicSignature
    } = useCommand()

    const settlementAccountChangedDocument = useSelector(state => selectors.documentIdToDocumentMapper(state)(deposit.documents)
        .filter(doc => doc.documentType === 'SETTLEMENT_ACCOUNT_CHANGED')
        .find(() => true) // Find first
    )
    const depositorAccounts = useSelector(state => selectors.depositorSettlementAccounts(state)[deposit.depositor.id] || [])
    const lookupSignatureStatus = useSelector(state => selectors.lookupSignatureStatus(state))
    const auth = useAuthorization(deposit.depositor.id)
    const hasOrderPermissions = auth.depositor.hasOrderRole || auth.depositor.hasPartnerAccountantRole
    const [showSigningError, setShowSigningError] = useState(false)

    async function onSubmitSettlementAccount() {
        if (settlementAccount.validate()) {
            const {waitForCommand} = await setSettlementAccount(deposit.id, settlementAccount.value)
            await waitForCommand()
        }
    }

    async function onSendToBank() {
        if (settlementAccountChangedDocument.signedByAll) {
            const {waitForCommand} = await sendSettlementAccountChangeToBank(deposit.id, deposit.pendingSettlementAccountId)
            await waitForCommand()
        } else {
            setShowSigningError(true)
        }
    }

    function onStartSignature(documentId) {
        const processId = uuidv4()
        startElectronicSignature(processId, 'SINGLE_DOCUMENT', documentId)
        navigate(`/signature/${processId}?context=/portfolio/id/${deposit.id}`)
    }

    function onAbort() {
        settlementAccount.reset()
        toggleChangeSettlementAccount()
    }

    type SettlementAccountData = {
        case: string,
        content: JSX.Element,
        actions: JSX.Element,
    }

    const ChangeSettlementAccountData: SettlementAccountData[] = [
        {
            case: 'DOCUMENT_CREATED',
            content: (
                <>
                    <p>{t('pages-portfolio-depositor.settlementAccountChangeDocumentCreated')}</p>
                    <SignableDocument
                        isCheckingSignatureStatus={lookupSignatureStatus(settlementAccountChangedDocument?.id)}
                        document={settlementAccountChangedDocument}
                        onStartSignature={() => onStartSignature(settlementAccountChangedDocument?.id)}
                        onRemoveDocument={hasOrderPermissions && (() => cancelChangeSettlementAccount(deposit.id))}
                        showSigningError={showSigningError}
                    />
                </>
            ),
            actions: (
                <ButtonRow>
                    <Button
                        variant="contained"
                        id="sendSettlementAccountChangeMessageButton"
                        onClick={onSendToBank}>
                        {t('pages-portfolio-depositor.changeSettlementAccountSendMessage')}
                    </Button>
                </ButtonRow>
            )
        },
        {
            case: 'DOCUMENT_SENT_TO_BANK',
            content: (
                <p>{t('pages-portfolio-depositor.settlementAccountChangeAwaitingConfirmation')}</p>
            ),
            actions: (
                <ButtonRow>
                    <Button variant="outlined"
                            onClick={toggleChangeSettlementAccount}>{t('pages-portfolio-depositor.changeSettlementAccountCloseWindow')}</Button>
                </ButtonRow>
            )
        },
        {
            case: 'CONFIRMED',
            content: (
                <>
                    <p>{t('pages-portfolio-depositor.settlementAccountMessageInfo')}</p>
                    <p>{t('pages-portfolio-depositor.selectSettlementAccountInfo')}</p>
                    <LabeledInput label={t('pages-portfolio-depositor.selectSettlementAccountLabel')}
                                  helpText={t('pages-portfolio-depositor.selectSettlementAccountHelpText')}
                                  errorMessage={!settlementAccount.valid && settlementAccount.errorMessage}>
                        <Select displayEmpty={true}
                                id="DepositChangeSettlementAccount_SettlementAccount"
                                onChange={e => settlementAccount.setValue(e.target.value)}
                                value={settlementAccount.value}>
                            <MenuItem value="">{t('common.selectOption')}</MenuItem>
                            {depositorAccounts?.map?.(account => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}&nbsp; ({account.account ? formatAccount(account.account, organisationCountry) : formatIban(account.iban)})
                                </MenuItem>
                            ))}
                        </Select>
                    </LabeledInput>
                </>
            ),
            actions: (
                <ButtonRow>
                    <Button variant="outlined" onClick={onAbort}>{t('common.cancel')}</Button>
                    <Button variant="contained" id="createSettlementAccountChangeMessageButton"
                            onClick={onSubmitSettlementAccount}>
                        {t('pages-portfolio-depositor.createChangeSettlementAccountMessage')}
                    </Button>
                </ButtonRow>
            )
        },
    ]

    return deposit.changedSettlementAccountStatus ? (
        <>
            {deposit.changedSettlementAccountStatus === 'DOCUMENT_CREATED' && (
                <>
                    <Alert severity="warning" sx={{mb: 2}}>
                        {t('pages-portfolio-depositor.changeSettlementNeedsSignatureAndSending')}
                    </Alert>
                    <Paper title={t('pages-portfolio-depositor.settlementAccountChangeHeader')}>
                        <Stack spacing={2}>
                            {ChangeSettlementAccountData.find(data => data.case === 'DOCUMENT_CREATED')?.content}
                            {ChangeSettlementAccountData.find(data => data.case === 'DOCUMENT_CREATED')?.actions}
                        </Stack>
                    </Paper>
                </>
            )}
            {deposit.changedSettlementAccountStatus === 'DOCUMENT_SENT_TO_BANK' && (
                <Alert severity="success" sx={{mb: 2}}>
                    {t('pages-portfolio-depositor.settlementAccountChangeAwaitingConfirmation')}
                </Alert>
            )}
            <FxDialog open={showChangeSettlementAccount} onClose={toggleChangeSettlementAccount}>
                <FxDialogTitle 
                    onClose={toggleChangeSettlementAccount}
                    supportCommonName='settlementAccounts'>{t('pages-portfolio-depositor.settlementAccountChangeHeader')}</FxDialogTitle>
                {ChangeSettlementAccountData.map(({case: status, content, actions}, index) => (
                    deposit.changedSettlementAccountStatus === status ? (
                        <Fragment key={index}>
                            <FxDialogContent>
                                <Box mt={2}>{content}</Box>
                            </FxDialogContent>
                            <FxDialogActions>{actions}</FxDialogActions>
                        </Fragment>
                    ) : null
                ))}
            </FxDialog>
        </>
    ) : null
}

