import useCurrentDepositor from '#app/services/useCurrentDepositor';
import { OrganisationalRole } from '@fixrate/fixrate-query';
import { SecurityRole } from '@fixrate/fixrate-security';
import { useCommand } from '#command';
import Modal from '#components/Modal';
import { useFieldState } from '@fixrate/fieldstate';
import { Box, Checkbox, FormControlLabel, FormLabel, Stack, TextField } from '@mui/material';
import { isAfter, isBefore, format } from 'date-fns';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from '#components/DateInput'

type Props = {
    close: () => void,
    securityRoles?: SecurityRole[],
    organisationalRoles?: OrganisationalRole[],
    initialFirstName?: string,
    initialLastName?: string,
    initialBirthDate?: string,
    initialAddress?: string,
}

const BrregNewPerson = function ({ close, securityRoles = [], organisationalRoles = [], initialFirstName = '', initialLastName = '', initialBirthDate = '', initialAddress = '' }: Props) {

    const { t } = useTranslation();
    const depositor = useCurrentDepositor();
    const { addPerson } = useCommand();
    const [showAllowSimilarNamesCheckbox, setShowAllowSimilarNamesCheckbox] = useState(false);
    const [allowSimilarNames, setAllowSimilarNames] = useState(false);

    const firstName = useFieldState(
        initialFirstName,
        ({ value }) => {
            if (!value) {
                return t('pages-organizations.newPersonFirstNameMissing');
            }
        },
    );

    const lastName = useFieldState(
        initialLastName,
        ({ value }) => {
            if (!value) {
                return t('pages-organizations.newPersonLastNameMissing');
            }
        },
    );

    const birthDate = useFieldState(initialBirthDate ? new Date(initialBirthDate) : null, ({ value, isEditing }) => {

        if (!isEditing) {
            if (isAfter(value, new Date())) {
                return t('components-DateInput.mustBeInPast')
            } else if (isBefore(value, new Date(1900, 0, 1))) {
                return t('components-DateInput.mustBeAfter1900')
            }
        }
    }, { validateInitially: true })

    const address = useFieldState(
        initialAddress,
    );

    async function submit() {
        const isValid = firstName.validate() && lastName.validate();

        if (!isValid) return;

        try {
            const birthDateFormatted = birthDate.value ? format(birthDate.value, 'yyyy-MM-dd') : null;
            const { waitForCommand, rejected } = await addPerson(
                depositor.id,
                firstName.value,
                lastName.value,
                securityRoles,
                organisationalRoles,
                allowSimilarNames,
                birthDateFormatted,
                address.value
            );

            if (rejected) {
                setShowAllowSimilarNamesCheckbox(true);
            } else {
                await waitForCommand();
                closeModal();
            }

        } catch (err) {
            console.error(err);
        }
    }

    const closeModal = () => {
        close();
    }

    const autoCompleteBlocker = (field) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1;
        return field + '-' + randomNumber;
    }

    return (
        <Modal
            header={t('pages-organizations.newPersonHeading')}
            onSubmit={submit}
            submitButtonId="newPersonByProff"
            onCancel={closeModal}
            width="50rem">
            <form autoComplete="off">
                <Stack spacing={1.5}>
                    <Box>
                        <FormLabel htmlFor="firstNameField">
                            {t('pages-organizations.newPersonFirstName')}
                        </FormLabel>
                        <TextField
                            id="firstNameField"
                            name={autoCompleteBlocker('fixrate-fname')}
                            onBlur={firstName.onBlur}
                            onChange={e => firstName.setValue(e.target.value)}
                            autoComplete="off"
                            value={firstName.value}
                        />
                        <p className="field-error-message">
                            {firstName.errorMessage}
                        </p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="lastNameField">
                            {t('pages-organizations.newPersonLastName')}
                        </FormLabel>
                        <TextField
                            id="lastNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={lastName.onBlur}
                            onChange={e => lastName.setValue(e.target.value)}
                            type="text"
                            autoComplete="off"
                            value={lastName.value}
                        />
                        <p className="field-error-message">
                            {lastName.errorMessage}
                        </p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="birthDateField">
                            {t('pages-organizations.personBirthDate')}
                        </FormLabel>
                        <DateInput
                            id='birthDateDatePicker'
                            value={birthDate.value}
                            onChange={(date) => birthDate.setValue(date)}
                            onBlur={() => {
                                birthDate.validate()
                                if (birthDate.valid) {
                                    birthDate.onBlur()
                                }
                            }}
                        />
                        <p className="field-error-message">
                            {birthDate.errorMessage}
                        </p>
                    </Box>
                    <Box>
                        <FormLabel htmlFor="addressField">
                            {t('pages-organizations.personAddress')}
                        </FormLabel>
                        <TextField
                            id="addressField"
                            name={autoCompleteBlocker('fixrate-address')}
                            onBlur={address.onBlur}
                            onChange={e => address.setValue(e.target.value)}
                            type="textarea"
                            autoComplete="off"
                            value={address.value}
                            multiline={true}
                            rows={4}
                        />
                    </Box>
                </Stack>

                {showAllowSimilarNamesCheckbox && (
                    <FormControlLabel
                        control={
                            <Checkbox id="allowSimilarNamesCheckbox" data-cy="allowSimilarNamesCheckbox" value={allowSimilarNames} checked={allowSimilarNames} onChange={(event: ChangeEvent<HTMLInputElement>) => setAllowSimilarNames(event.target.checked)} />
                        }
                        label={t('pages-organizations.allowSimilarNames')} />
                )}
            </form>
        </Modal>
    );
}

export default BrregNewPerson;