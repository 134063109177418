import DepositorPartnerProposalSignup from '#pages/PartnerDepositorProposalWizard/DepositorPartnerProposalSignup'
import InviteLandingPage from '#app/layers/Signup/InviteLandingPage'
import RegisterCompanyNO from '#app/layers/Signup/RegisterCompanyNO'
import RegisterCompanySE from '#app/layers/Signup/RegisterCompanySE'
import Welcome from '#app/layers/Signup/Welcome'
import Login from '#app/layers/Login/Login'
import Signup from '#app/layers/Signup/Signup'
import Pin from '#app/layers/Pin/Pin'
import ChooseDepositor from '#app/layers/ChooseDepositor/ChooseDepositor'
import {AssociationDto, AuthenticationLevel} from '@fixrate/fixrate-security'
import {Location, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {useSelector} from '#state/useSelector'
import {parse} from 'query-string'
import {useEffect} from 'react'
import * as analytics from '#services/analytics'

const OPEN_PATHS = [
    /^\/$/,
    /^\/marketplace$/,
    /^\/marketplace\/$/,
    /^\/marketplace\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b$/i,
    /^\/marketplace\/onboarding$/,
    /^\/marketplace\/onboarding\/$/,
    /^\/marketplace\/onboarding\/bank$/,
    /^\/marketplace\/onboarding\/reports$/,
    /^\/marketplace\/onboarding\/potential$/,
]

const hasQueryParam = (location: Location, paramName: string) => {
    const query = parse(location.search)
    return query[paramName] !== undefined
}

const getQueryParam = (location: Location, paramName: string): string => {
    const query = parse(location.search)
    return query[paramName]
}

export type OverlayType = 'login' | 'signup' | 'pin' | 'chooseDepositor' | 'welcome' | 'registerCompany' | 'partnerProposal' | 'partnerAuthorization' | 'inviteLanding' | 'loginInRegistrationMode' | null

/**
 * Use authentication status and location to select an overlay
 */
function selectOverlay(authenticated: boolean, authenticationLevel: AuthenticationLevel, location: Location): OverlayType {

    //
    // NB! The order of the if statements is important!
    //

    const pathNameIsPartnerAuthorization = location.pathname.startsWith('/partner/signature/')
    if (authenticationLevel !== 'AUTHENTICATED' && pathNameIsPartnerAuthorization) {
        return 'partnerAuthorization'
    }

    const pathNameIsProposal = location.pathname.startsWith('/partner/proposals/')
    if (authenticationLevel !== 'AUTHENTICATED' && pathNameIsProposal) {
        return 'partnerProposal'
    }

    if ((authenticationLevel === 'UNKNOWN' || authenticationLevel === 'KNOWN') && location.pathname.startsWith('/invite/') && !hasQueryParam(location, 'continue')) {
        return 'inviteLanding'
    }

    if (authenticated && location.pathname === '/marketplace' && hasQueryParam(location, 'registerCompany')) {
        return 'registerCompany'
    }
    if (
        authenticationLevel === 'UNKNOWN' &&
        location.pathname === '/marketplace' &&
        !hasQueryParam(location, 'login') &&
        !hasQueryParam(location, 'register') &&
        !hasQueryParam(location, 'sso')
    ) {
        return 'welcome'
    }
    if (authenticationLevel === 'UNKNOWN' && location.pathname === '/marketplace' && hasQueryParam(location, 'register')) {
        return 'loginInRegistrationMode'
    }

    // We force the Signup component if not authenticated, but identified (that is, user logged in but not a user yet)
    if (authenticationLevel === 'IDENTIFIED') {
        return 'signup'
    }

    // Show login overlay if user is not authenticated and path is not an open path
    if (!authenticated && (!OPEN_PATHS.find(pathRegex => location.pathname.match(pathRegex)) || hasQueryParam(location, 'login'))) {
        return 'login'
    }

    // We force to unlock with pin if session has timed out
    if (authenticationLevel === 'LOCKED') {
        return 'pin'
    }

    return null
}


type ForceLoginProps = {
    requestedOrganisationId: string,
    association: AssociationDto,
    associations: AssociationDto[],
    authenticated: boolean
}

type ForceLoginReturn = {
    forceLogin: boolean
    idpId?: string[]
}

function forceLogin({requestedOrganisationId, association, associations, authenticated}: ForceLoginProps): ForceLoginReturn {
    const hasUnresolvedRequest = requestedOrganisationId != null && association?.organisation.id !== requestedOrganisationId
    const hasOnlySingleUnaccessibleAssociation = associations?.length === 1
    if (hasOnlySingleUnaccessibleAssociation) {
        if (authenticated && associations[0].missingIdpIds.length > 0) {
            return {forceLogin: true, idpId: associations[0].missingIdpIds}
        }
    }
    if (hasUnresolvedRequest) {
        const requestedAssociation = associations.find(a => a.organisation.id === requestedOrganisationId)
        if (requestedAssociation) {
            return {
                forceLogin: true,
                idpId: requestedAssociation.missingIdpIds,
            }
        }
    }
    return {forceLogin: false} // No need to force login
}

function logPageView({ overlayType, location, association, associations } : { overlayType: OverlayType, location: Location, association: AssociationDto, associations: AssociationDto[]}) {
    let overlayUrl: string
    switch(overlayType) {
        case 'login': overlayUrl = '/login'; break
        case 'signup': overlayUrl = '/signup'; break
    }
    if (overlayUrl) {
        analytics.pageView({path: overlayUrl + location.search, association, associations})
    }
}

/**
 * Shows different overlays based on the current state of the application
 */
export function Overlay() {

    // NB!! Be very careful and precise with selectors here. If the result of these selectors changes, then the whole page will re-render
    // Only select exactly the data you actually need

    const location = useLocation()
    const navigate = useNavigate()

    const authenticated = useSelector(state => state.session?.authenticated)
    const authenticationLevel = useSelector(state => state.session?.authenticationLevel)
    const association = useSelector(state => state.session?.association)
    const associations = useSelector(state => state.session?.associations)
    const primaryDomain = useSelector(state => state.session.primaryDomain)
    const requestedOrganisationId = useSelector(state => state.session?.requestedOrganisationId)

    // Finds the correct overlay, if any
    const overlayType = selectOverlay(authenticated, authenticationLevel, location)

    // Logs a page view when specific overlays are shown
    useEffect(() => {
        logPageView({overlayType, location, association, associations})
    }, [overlayType, location, association, associations])

    // Callback that navigates to the correct page after a successful login
    const onLoginSuccess = () => {
        if (location.pathname === '/marketplace' || location.pathname === '/marketplace?login') {
            navigate('/')
        }
    }

    // Special override! Do NOT show any overlays if the user is on the logged-out page
    if (location.pathname.includes('/partner/logged-out/')) {
        return null
    }

    // Creates the correct overlay based on the overlay type
    switch(overlayType) {

        case 'partnerProposal':
            return (
                <Routes>
                    <Route path="/partner/proposals/:partnerId/:proposalId/:inviteId"
                           element={<DepositorPartnerProposalSignup/>}/>
                    <Route path="/partner/proposals/:partnerId/:proposalId"
                           element={<DepositorPartnerProposalSignup/>}/>
                </Routes>
            )

        case 'partnerAuthorization':
            return (
                <Routes>
                    <Route path="/partner/signature/:partnerId/:inviteId"
                           element={<DepositorPartnerProposalSignup signingRedirect/>}/>
                    <Route path="/partner/signature/:partnerId"
                           element={<DepositorPartnerProposalSignup signingRedirect/>}/>
                </Routes>
            )

        case 'inviteLanding': {
            const inviteId = location.pathname.substring('/invite/'.length)
            return <InviteLandingPage inviteId={inviteId}/>
        }

        case 'registerCompany':
            switch (primaryDomain) {
                case 'fixrate.no':
                    return <RegisterCompanyNO/>
                case 'fixrate.se':
                    return <RegisterCompanySE/>
            }
            return <RegisterCompanyNO/>

        case 'welcome':
            return <Welcome/>

        case 'loginInRegistrationMode':
            return <Login registration={true}/>

        case 'signup':
            return <Signup/>

        case 'login': {
            return <Login method={[getQueryParam(location, 'method')]} onLoginSuccess={() => onLoginSuccess()}/>
        }
        case 'pin':
            return <Pin/>
    }

    // Show login overlay if the user has requested an organisation that requires a different Idp than the current
    const forceLoginResult = forceLogin({requestedOrganisationId, association, associations, authenticated})
    if (forceLoginResult.forceLogin) {
        return <Login forceIdp={true} method={forceLoginResult.idpId} logoutOnCancel={true}/>
    }

    // Show choose depositor if authenticated but no association. This must run after the forceLogin check.
    if (authenticated && !association) {
        return <ChooseDepositor/>
    }

    return null
}
