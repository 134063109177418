import { Fragment, useState } from 'react';
import FundBuyOrderTable from './FundBuyOrderTable'
import {useSelector} from '#state/useSelector'
import {PageHeader, PageLayout} from '#components'
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, TextField, Typography} from '@mui/material'
import {FundBuyOrderDto, FundBuyOrderState, FundCustomerDto, FundDto} from '@fixrate/fixrate-query'
import {useTranslation} from 'react-i18next'
import {ExpandMore} from '@mui/icons-material'

interface OrderGroup {
    id: FundBuyOrderState
    label: string
    orders: FundBuyOrderDto[]
    defaultExpanded: boolean
}

export default function FundBuyOrderOverview() {
    const {t} = useTranslation()

    const fundBuyOrders = useSelector(state => state.fundBuyOrders)

    const [fundFilter, setFundFilter] = useState<FundDto | null>(null)
    const [customerFilter, setCustomerFilter] = useState<FundCustomerDto | null>(null)

    const funds = useSelector(state => state.funds)
    const fundCustomers = useSelector(state => state.fundCustomers)

    const filteredFundBuyOrders = fundBuyOrders
        .filter(fundBuyOrder => !fundFilter || fundBuyOrder.fundId === fundFilter.id)
        .filter(fundBuyOrder => !customerFilter || fundBuyOrder.depositorId === customerFilter.depositorId)

    const orderGroups: OrderGroup[] = [
        {id: 'AWAITING_SIGNATURE', label: t('pages.fundOverview.createdOrders'), orders: filteredFundBuyOrders.filter(o => o.state === 'AWAITING_SIGNATURE'), defaultExpanded: true},
        {id: 'AWAITING_PAYMENT', label: t('pages.fundOverview.signedOrders'), orders: filteredFundBuyOrders.filter(o => o.state === 'AWAITING_PAYMENT'), defaultExpanded: true},
        {id: 'AWAITING_PAYMENT_CONFIRMATION', label: t('pages.fundOverview.paymentReported'), orders: filteredFundBuyOrders.filter(o => o.state === 'AWAITING_PAYMENT_CONFIRMATION'), defaultExpanded: true},
        {id: 'IN_PROGRESS', label: t('pages.fundOverview.paymentConfirmed'), orders: filteredFundBuyOrders.filter(o => o.state === 'IN_PROGRESS'), defaultExpanded: true},
        {id: 'EXECUTING_IN_MARKET', label: t('pages.fundOverview.executingInMarket'), orders: filteredFundBuyOrders.filter(o => o.state === 'EXECUTING_IN_MARKET'), defaultExpanded: true},
        {id: 'COMPLETED', label: t('pages.fundOverview.completed'), orders: filteredFundBuyOrders.filter(o => o.state === 'COMPLETED'), defaultExpanded: false},
        {id: 'CANCELLED', label: t('pages.fundOverview.cancelled'), orders: filteredFundBuyOrders.filter(o => o.state === 'CANCELLED'), defaultExpanded: false},
        {id: 'CANCELLED_BY_DEPOSITOR', label: t('pages.fundOverview.cancelledByDepositor'), orders: filteredFundBuyOrders.filter(o => o.state === 'CANCELLED_BY_DEPOSITOR'), defaultExpanded: false},
    ]

    return (
        <Fragment>
            <PageHeader title={t('pages.fundOverview.buyOrdersTitle')}/>
            <PageLayout>
                <>
                    <Box sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, gap: 1, mb: 2}}>
                        <Autocomplete
                            sx={{flex: 1}}
                            value={fundFilter}
                            options={funds}
                            onChange={(event, fund: FundDto | undefined) => setFundFilter(fund ?? null)}
                            autoHighlight
                            clearOnEscape
                            getOptionLabel={(option: FundDto) => `${option.lei} – ${option.name}`}
                            renderInput={(params) => <TextField label={t('pages.fundOverview.filterFund')} {...params} />}
                        />
                        <Autocomplete
                            sx={{flex: 1}}
                            value={customerFilter}
                            options={fundCustomers}
                            onChange={(event, fundCustomer: FundCustomerDto | undefined) => setCustomerFilter(fundCustomer ?? null)}
                            autoHighlight
                            clearOnEscape
                            getOptionLabel={(option: FundCustomerDto) => option.name}
                            renderInput={(params) => <TextField label={t('pages.fundOverview.filterCustomer')} {...params} />}
                        />
                    </Box>
                    {orderGroups.map(orderGroup => (
                        <Accordion key={orderGroup.id} defaultExpanded={orderGroup.defaultExpanded}>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                <Typography>{`${orderGroup.label} (${orderGroup.orders.length})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FundBuyOrderTable fundBuyOrders={orderGroup.orders}/>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            </PageLayout>
        </Fragment>
    );
}
