import styles from './AppLoadingPage.module.scss'
import StatusMessageBanner from '#blocks/StatusMessageBanner/StatusMessageBanner'
import { AuthenticationLevel } from '@fixrate/fixrate-security'
import {useSelector} from '#state/useSelector'

const showAppLoading = ({authenticationLevel, messagesIsLoaded, unknownState}: {
    authenticationLevel: AuthenticationLevel,
    messagesIsLoaded: boolean,
    unknownState: boolean
}) => unknownState || (!messagesIsLoaded && authenticationLevel === 'AUTHENTICATED')

export default function AppLoadingPage({children}: {children: React.ReactNode}) {

    // NB!! Be very careful and precise with selectors here. If the result of these selectors changes, then the whole page will re-render
    // Only select exactly the data you actually need

    const unknownState = useSelector(state => state.session?.unknownState)
    const authenticationLevel = useSelector(state => state.session?.authenticationLevel)
    const messagesIsLoaded = useSelector(state => state.loaded.messages)

    if (!showAppLoading({authenticationLevel, messagesIsLoaded, unknownState})) {
        return <>{children}</>
    }

    return (
        <div className={styles.iconWrapper}>
            <div>
                <img src="/images/fixrate-logo.svg" alt={''}/>
                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" stroke="#4D2A98">
                    <g fill="none" fillRule="evenodd" strokeWidth="2">
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                        </circle>
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
                            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
                        </circle>
                    </g>
                </svg>
                <div style={{marginTop: 32}}>
                    <StatusMessageBanner/>
                </div>
            </div>
        </div>
    )
}
