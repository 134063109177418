import { useState } from 'react';
import styles from './OrderProcess.module.scss'
import {DepositorDto, OrganisationalRole, SecurityRole} from '@fixrate/fixrate-query'
import BadgeList from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/BadgeList'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import BrregBoard from '#pages/organizations/OrganizationDetail/UsersAndRoles/views/BrregBoard'
import BrregBox from '#pages/organizations/OrganizationDetail/UsersAndRoles/views/BrregBox'
import {Stack, Typography} from "@mui/material";
import {HelpIcon} from "#components";
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';

type Props = {
    depositor: DepositorDto
    inline?: boolean,
    hideKeyPerson?: boolean
}

export default function Board({depositor, inline, hideKeyPerson}: Props) {
    const {t} = useTranslation()

    const hasBoardChanges = depositor.peopleChanges.hasBoardChanges
    const [brregInfoVisible, setBrregInfoVisible] = useState(!!hasBoardChanges)
    const organisationCountry = useCurrentCountryCode()

    // Only a single AddUserButton can be open at the same time in this context
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const closeOpenRoleOnClickOutside = () => {
        if (selectedOpenRole != null) {
            setSelectedOpenRole(null)
        }
    }

    const getKeyPersonRoleHtml = (person) => person.keyPersonRole ?
        <span>{person.keyPersonRole}</span> :
        <span><i className="ri-error-warning-line"/><span>{t('pages-organizations.boardOtherKeyRoleNotSpecified')}</span></span>

    const nonSelectableRoles = ['DEPOSITOR_PARTNER_SUPPORT']

    const selectablePeople = depositor.people.filter(person => person.securityRoles.every(role => !nonSelectableRoles.includes(role)))

    const filteredBoardPersonMatches = depositor.peopleChanges.boardPersonMatches.filter(
        person =>
            person.boardRole.fixrateRole !== null ||
            person.boardRole.proffRole !== null ||
            person.managementRole.fixrateRole !== null ||
            person.managementRole.proffRole !== null
    );

    const hasNonExactMatch = filteredBoardPersonMatches.some(person => person.matchType !== 'EXACT');


    
    return (
        <Stack spacing={4} className={classNames(inline ? styles.inline : null)} onClick={closeOpenRoleOnClickOutside}>
            <Stack>
                { !inline && (
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant='h2'>
                            <i className='ri-group-line' />
                            <span>{t('pages-organizations.boardBoardStructure')}</span>
                        </Typography>
                    </Stack>
                )}

                <p>{t('pages-organizations.boardBoardStructureDescription')}</p>

                {organisationCountry === 'NO' && (
                    <Stack paddingBottom={1}>

                        {!inline && depositor?.regulatoryRegion === 'NO' && (
                            <BrregBox header={t('pages-organizations.boardBrregDescription')} infoVisible={brregInfoVisible} setInfoVisible={setBrregInfoVisible} hasPeopleChanges={hasBoardChanges} hasNonExactMatch={hasNonExactMatch}>
                                <BrregBoard depositor={depositor} />
                            </BrregBox>
                        )}
                    </Stack>
                )}

                <div className={styles.boardSectionHeader}>{t('pages-organizations.boardChairman')}</div>
                <BadgeList
                    selectedOpenRole={selectedOpenRole}
                    setSelectedOpenRole={setSelectedOpenRole}
                    depositor={depositor}
                    people={selectablePeople}
                    organisationalRole='BOARD_CHAIRMAN'
                    showPersonalWarnings={inline !== true}
                    missingRoleFeedback={t('pages-organizations.boardChairmanMissingFeedback')}
                    maxNoInRole={1}
                    row
                />

                <div className={styles.boardSectionHeader}>{t('pages-organizations.boardMembers')}</div>
                <BadgeList showPersonalWarnings={inline !== true} selectedOpenRole={selectedOpenRole} setSelectedOpenRole={setSelectedOpenRole} depositor={depositor} people={selectablePeople} organisationalRole='BOARD_MEMBER' row />
            </Stack>
            <Stack spacing={1}>
                <Stack direction={'row'} spacing={1}>
                    <Typography variant='h2'>
                        <i className='ri-user-line' />
                        <span>{t('pages-organizations.boardCeoOrKeyPerson')}</span>
                    </Typography>
                </Stack>
                <BadgeList
                    selectedOpenRole={selectedOpenRole}
                    setSelectedOpenRole={setSelectedOpenRole}
                    depositor={depositor}
                    people={selectablePeople}
                    organisationalRole='CEO'
                    showPersonalWarnings={inline !== true}
                    missingRoleFeedback={t('pages-organizations.ceoMissingFeedback')}
                    maxNoInRole={1}
                    row
                />
            </Stack>
            {!hideKeyPerson && (
                <Stack spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                        <Typography variant='h2'>
                            <i className='ri-user-line' />
                            <span>{t('pages-organizations.keyPersonHeader')}</span>
                        </Typography>
                        <HelpIcon sx={{ width: '2.2rem', height: '2.2rem' }} supportCommonName="ownershipKeyPeople" />
                    </Stack>
                    <p>{t('pages-organizations.keyPersonDescription')}</p>
                    <BadgeList
                        selectedOpenRole={selectedOpenRole}
                        setSelectedOpenRole={setSelectedOpenRole}
                        depositor={depositor}
                        people={selectablePeople}
                        organisationalRole='KEY_PERSON'
                        showPersonalWarnings={inline !== true}
                        getHeader={getKeyPersonRoleHtml}
                        row
                    />
                </Stack>
            )}
        </Stack>
    )
}
