import { useCommand } from '#command';
import {CurrencyOutput} from '#components';
import ClipboardCopyButton from '#components/ClipboardCopyButton';
import { useTranslation } from '#components/i18n';
import { showConfirmationModal } from '#state/reducers/confirmationModal';
import { LoadingButton } from '@mui/lab';
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel,
    List, Stack,
    TextField
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DetailRow from './components/DetailRow';
import { FundBuyOrderRow } from './FundBuyOrderTable';
import {useSelector} from "#state/useSelector";
import DatePicker from "#components/DatePicker";
import format from "date-fns/format";
import {formatAccount} from "#services/formatnumber";

interface ExportBuyOrderToFaResponse {
    resultStatus: string
    description?: string
}

export default function RegisterInFaDialog({fundBuyOrderRow, onClose}: { fundBuyOrderRow: FundBuyOrderRow | null, onClose: () => void }) {
    const {t} = useTranslation('pages-marketplace')
    const dispatch = useDispatch()

    const {cancelFundBuyOrder, registerFundBuyOrderInFa} = useCommand()
    const [submittingCancel, setSubmittingCancel] = useState(false)
    const [submittingRegister, setSubmittingRegister] = useState(false)
    const [enableExportToFA, setEnableExportToFA] = useState(false)
    const [exportingToFA, setExportingToFA] = useState(false)
    const [transactionDate, setTransactionDate] = useState<Date>(new Date())
    const fundShareClass = useSelector(state => state.funds.flatMap(f => f.fundShareClasses).find(sc => sc.id === fundBuyOrderRow?.fundShareClassId))
    if (!fundShareClass || !fundBuyOrderRow) {
        return null
    }

    const canExport = !fundBuyOrderRow.registeredInFa
        && fundBuyOrderRow.id != null
        && fundBuyOrderRow.amount != null && fundBuyOrderRow.amount > 0
        && fundBuyOrderRow.accountNumber != null
        && fundBuyOrderRow.cid != null
        && fundBuyOrderRow.state != null
        && fundBuyOrderRow.state !== 'AWAITING_SIGNATURE'
        && fundShareClass.isin != null
        && enableExportToFA
        && transactionDate != null
        && !exportingToFA

    async function exportBuyOrderRequestToFa() {
        if (!canExport) {
            return
        }
        try {
            setExportingToFA(true)
            const cid = fundBuyOrderRow.cid
            const faPortfolioId = cid.substring(0, cid.length - 1)
            const request = {
                fundOrderId: fundBuyOrderRow.id,
                tradeAmount: fundBuyOrderRow.amount,
                transactionDate: format(transactionDate, 'yyyy-MM-dd'),
                isinCode: fundShareClass.isin,
                cashAccountNumber: fundBuyOrderRow.accountNumber,
                description: 'IMPORT: Kjøp av fondsandeler via Fixrate Marketplace',
            }
            const response = await fetch(`/api/fund-actions/portfolio/${faPortfolioId}/buy-order`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify(request)
                })
            if (!response.ok) {
                dispatch(showConfirmationModal({
                    title: 'Feilrespons ved opplasting til FA',
                    text: response.statusText,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }))
                return
            }
            const data: ExportBuyOrderToFaResponse = await response.json()
            await handleExportBuyOrderResponse(data)
        } catch (e) {
            dispatch(showConfirmationModal({
                title: 'Feil ved lasting til FA',
                text: 'Ukjent feil ved lasting til FA: ' + e,
                buttonText: t('common.continue'),
                confirmationIconName: 'error',
            }))
        } finally {
            setExportingToFA(false)
            onClose()
        }
    }

    async function handleExportBuyOrderResponse(exportBuyOrderResponse: ExportBuyOrderToFaResponse): Promise<void> {
        switch (exportBuyOrderResponse.resultStatus) {
            case 'SUCCESS': {
                const {waitForCommand} = await registerFundBuyOrderInFa(fundBuyOrderRow.id)
                const registerSuccess = await waitForCommand()
                if (!registerSuccess) {
                    dispatch(showConfirmationModal({
                        title: 'Feil ved intern registrering',
                        text: 'Ordren ble opplastet til FA, men intern registrering feilet. Prøv å trykk på "Ordren er registert i FA" manuelt.',
                        buttonText: t('common.continue'),
                    }))
                    break
                }
                dispatch(showConfirmationModal({
                    title: 'Ordren er lastet opp til FA og registrert internt!',
                    text: 'Ordren er lastet opp til FA og registrert internt!',
                    buttonText: t('common.continue'),
                }))
                break
            }
            case 'FAILURE':
            default:
                dispatch(showConfirmationModal({
                    title: 'Feil ved opplasting til FA',
                    text: 'Feil ved opplasting til FA: ' + exportBuyOrderResponse.description,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }))
                break
        }
    }

    async function handleSubmitCancel() {
        if (!fundBuyOrderRow) return

        setSubmittingCancel(true)
        const {waitForCommand} = await cancelFundBuyOrder(fundBuyOrderRow.id)
        const success = await waitForCommand()
        setSubmittingCancel(false)
        if (success) {
            dispatch(showConfirmationModal({
                title: 'Ordren er avbrutt!',
                text: 'Ordren er avbrutt!',
                buttonText: t('common.continue'),
                sentIcon: false,
            }))
            onClose()
        }
    }

    async function handleSubmitRegister() {
        if (!fundBuyOrderRow || fundBuyOrderRow.state == null || fundBuyOrderRow.state === 'AWAITING_SIGNATURE') return

        setSubmittingRegister(true)
        const {waitForCommand} = await registerFundBuyOrderInFa(fundBuyOrderRow.id)
        const success = await waitForCommand()
        setSubmittingRegister(false)
        if (success) {
            dispatch(showConfirmationModal({
                title: 'Registrering bekreftet!',
                text: 'Registrering bekreftet!',
                buttonText: t('common.continue'),
                sentIcon: false,
            }))
            onClose()
        }

    }

    const isin = fundShareClass.isin ?? '-'
    const fundShareClassName = fundShareClass.fullName ?? '-'
    const accountNumber = fundBuyOrderRow.accountNumber ?? ''
    const regulatoryRegion = accountNumber.length === 11 ? "NO" : "SE" // TODO: Add regulatory region to FundCustomerDto.

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Manuell registrering i FA</DialogTitle>
            {fundBuyOrderRow && (
                <DialogContent>
                    <DialogContentText sx={{mb: 2}}>Registrer denne ordren i FA før du bekrefter registeringen her.</DialogContentText>
                    <List dense={true}>
                        <DetailRow title={'Andelsklasse'}>{fundShareClassName}</DetailRow>
                        <DetailRow title={'ISIN'}>
                            <div>
                                <span>{isin}</span>
                                <ClipboardCopyButton inputText={isin}/>
                            </div>
                        </DetailRow>
                        <DetailRow title={'Innskyter'}>{fundBuyOrderRow.depositorName}</DetailRow>
                        <DetailRow title={'Sum'}>
                            <div>
                                {CurrencyOutput.formatNoCode(fundBuyOrderRow.amount)}
                                <ClipboardCopyButton inputText={fundBuyOrderRow.amount.toString().replace('.', ',') ?? ''}/>
                            </div>
                        </DetailRow>
                        <DetailRow title={'External ID'}>
                            <div>
                                <span>{fundBuyOrderRow.id}</span>
                                <ClipboardCopyButton inputText={fundBuyOrderRow.id ?? ''}/>
                            </div>
                        </DetailRow>
                        <DetailRow title={'Konto'}>
                            <div>
                                <span>{accountNumber.length > 4 ? formatAccount(accountNumber, regulatoryRegion) : accountNumber}</span>
                                <ClipboardCopyButton inputText={accountNumber}/>
                            </div>
                        </DetailRow>
                        <DetailRow title={'KID/OCR'}>
                            <div>
                                <span>{fundBuyOrderRow.cid}</span>
                                <ClipboardCopyButton inputText={fundBuyOrderRow.cid ?? ''}/>
                            </div>
                        </DetailRow>
                        <FormControl>
                            <FormControlLabel
                                sx={{mt: 1, mb: 1}}
                                control={<Checkbox
                                    id="enableExportToFAInput"
                                    checked={enableExportToFA}
                                    onChange={() => setEnableExportToFA(!enableExportToFA)}
                                />}
                                label={'Last opp til FA?'}
                            />
                            {enableExportToFA && (
                                <Stack direction={'row'} spacing={1}>
                                    <DatePicker
                                        id="transactionDateInput"
                                        selected={transactionDate}
                                        onChange={date => setTransactionDate(date)}
                                        customInput={
                                        <TextField label={'Transaksjonsdato'}
                                                   helperText={!transactionDate && 'Transaksjonsdato må fylles ut'}
                                                   error={!transactionDate}
                                        />}
                                    />
                                    <LoadingButton
                                        data-cy="submitBuyOrderButton"
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={exportBuyOrderRequestToFa}
                                        disabled={exportingToFA}
                                        loading={exportingToFA}
                                    >
                                        Last opp til FA
                                    </LoadingButton>
                                </Stack>
                            )}
                        </FormControl>
                    </List>
                </DialogContent>
            )}
            <DialogActions>
                <Button data-cy="cancelButton" onClick={onClose}>Avbryt</Button>
                <LoadingButton
                    data-cy="cancelOrderButton"
                    variant={'contained'}
                    color={'error'}
                    disabled={submittingCancel}
                    loading={submittingCancel}
                    onClick={() => window.confirm('Er du sikker på at du vil avbryte bestillingen?') && handleSubmitCancel()}
                >
                    Avbryt ordren
                </LoadingButton>
                <LoadingButton
                    data-cy="submitRegisterButton"
                    variant={'contained'}
                    onClick={handleSubmitRegister}
                    disabled={submittingRegister}
                    loading={submittingRegister}
                >
                    Ordren er registert i FA
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
