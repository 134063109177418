import { useSelector } from '#app/state/useSelector'
import NIBORPortfolioInterestRatesChart from '#pages/portfolio-depositor/PortfolioInterestRatesChart'
import { Box, Stack, Typography } from '@mui/material'
import isAfter from 'date-fns/isAfter'
import { useTranslation } from 'react-i18next'
import SegmentChart from '../SegmentChart'
import {useCurrencyOutput} from "#components/CurrencyOutput/useCurrencyOutput";
import {usePortfolioValues} from "#services/usePortfolioValues";

export default function DepositsOverview() {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()

    const portfolioValues = usePortfolioValues()
    const deposits = useSelector(state => state.deposits)

    const activeDeposits = deposits.filter(deposit => !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date()))
    const numberOfBanks = [...new Set(activeDeposits.map(deposit => deposit.bankId))].length
    const showSegmentChart = activeDeposits.length > 1 && numberOfBanks > 1

    const currency = useSelector(state => state.session?.association?.currentCurrency)

    return (
        <Stack direction={{xs: 'column', md: 'row'}} spacing={4}>
            {showSegmentChart && (
                <Box>
                    <SegmentChart deposits={activeDeposits} />
                </Box>
            )}
            { currency === "NOK" && (
                <Stack spacing={2}>
                    <Typography
                        variant={'body1'}
                        sx={{
                            fontSize: {
                                xs: '4rem',
                                md: '5.0rem'
                            },
                            fontWeight: 700
                        }}>{Currency(portfolioValues?.deposits, { decimals: 0 })}</Typography>
                    <Stack spacing={1} sx={{maxWidth: '68rem'}}>
                        <Typography variant={'subtitle1'} sx={{fontWeight: 600}}>{t('pages-portfolio-depositor.historicalInterestRate')}</Typography>
                        <NIBORPortfolioInterestRatesChart />
                    </Stack>

                </Stack>
            )}
        </Stack>
    )
}
