import CompanySelect from '#app/components/CompanySelect'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import {PartnerCustomerDto} from '@fixrate/fixrate-query'
import {useCommand} from '#command'
import {useSelector} from '#state/useSelector'
import {LoadingButton} from '@mui/lab'
import {Box, IconButton, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {Fragment, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styles from './RegisterCustomer.module.scss'
import {CompanyInfo} from '#app/components/CompanySelect/CompanySelect'
import Illustration from './RegistrationIllustration.svg?react'
import {FOREST_GREEN} from '#app/colors/colors'

export default function RegisterCustomer({ hide, visible, onSuccess }: Readonly<{ hide: () => void; visible: boolean; onSuccess?: (customer: PartnerCustomerDto) => void }>) {
    const { t } = useTranslation()

    const [company, setCompany] = useState<CompanyInfo | null>(null)
    const [companyError, setCompanyError] = useState('')
    const [callbackDidRun, setCallbackDidRun] = useState(false)
    const [selectedAdvisor, setSelectedAdvisor] = useState(null)
    const [loading, setLoading] = useState(false)
    const partner = useSelector(state => state.partner)
    const currentUserId = useSelector(state => state.session.id)
    const isPartnerAdmin = partner?.users?.find(user => user.id === currentUserId)?.roles.includes('PARTNER_ADMIN')
    const { sendPartnerInvite } = useCommand()
    const { addPartnerAdvisor } = useCommand()

    useEffect(() => {
        if (!selectedAdvisor) {
            setSelectedAdvisor(currentUserId)
        }
    }, [currentUserId, selectedAdvisor])

    const resetForm = useCallback(() => {
        setLoading(false)
        setCompany(null)
        setCompanyError('')
        setSelectedAdvisor(currentUserId)
        hide()
    }, [currentUserId, hide])

    // This useEffect runs a callback after the customer is created that will
    // 1. add the advisor to the customer
    // 2. run any custom callback
    // TODO: This is a hack, we should find a better way to do this. Probably by adding the advisor server side
    useEffect(() => {
        if (!callbackDidRun) {
            const customer = partner.customers.find(customer => customer.nationalIdentity === company?.nationalIdentity)
            if (customer ) {
                setCallbackDidRun(true) // This avoids the callback to run more than once
                ;(async () => {
                    await addPartnerAdvisor(partner.id, customer?.depositorId, selectedAdvisor)
                    resetForm()
                    if (onSuccess) {
                        onSuccess(customer)
                    }
                })()
            }
        }
    }, [callbackDidRun, addPartnerAdvisor, company?.nationalIdentity, onSuccess, partner.customers, partner.id, resetForm, selectedAdvisor])

    async function onSubmit() {
        setLoading(true)
        if (!company) {
            setCompanyError(t('pages-customers.inviteCompanySelectValidation'))
            setLoading(false)
        } else {
            const { waitForCommand } = await sendPartnerInvite(partner.id, company.nationalIdentity, company.nationality, true)
            const success = await waitForCommand()
            if (success) {
                console.log('Successfully created customer')
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
    }

    return (
        <FxDialog data-cy='registerCustomerDialog' fullWidth={true} open={visible} onClose={resetForm}>
            <Box sx={{ position: 'absolute', right: '1.6rem', top: '1.6rem' }}>
                <IconButton sx={{ fontSize: '2.4rem', color: FOREST_GREEN[500] }} onClick={resetForm}>
                    <i className='ri-close-line' />
                </IconButton>
            </Box>
            <Box sx={{ backgroundColor: '#FDF3E5', padding: '4rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Illustration />
            </Box>
            <FxDialogContent sx={{ padding: '4rem' }} className={styles.registerCustomer}>
                <Fragment>
                    <h2>{t('pages-customers.registerNewCustomer')}</h2>
                    <label className='flex'>{t('pages-customers.searchForOrganization')}</label>
                    <CompanySelect onChange={setCompany} setErrorMessage={setCompanyError} placeholder={'Søk'} />
                    <p className='field-error-message'>{companyError}</p>
                    {isPartnerAdmin && (
                        <Fragment>
                            <label className='flex'>{t('pages-customers.accountant')}</label>
                            <Select value={selectedAdvisor} onChange={(e: SelectChangeEvent) => setSelectedAdvisor(e.target.value)}>
                                {partner?.users.map(user => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Fragment>
                    )}
                    <Box sx={{ marginTop: '3rem' }}>
                        <LoadingButton data-cy='registerCustomerButton' loading={loading} variant='contained' onClick={() => onSubmit()}>
                            {t('pages-customers.registerCustomer')}
                        </LoadingButton>
                    </Box>
                </Fragment>
            </FxDialogContent>
        </FxDialog>
    )
}
