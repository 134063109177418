import { Fragment, useState } from 'react';
import FundSellOrderTable from './FundSellOrderTable'
import {PageHeader, PageLayout} from '#components'
import {useSelector} from '#state/useSelector'
import {FundCustomerDto, FundDto, FundSellOrderDto, FundSellOrderState} from '@fixrate/fixrate-query'
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, TextField, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {ExpandMore} from '@mui/icons-material'

interface OrderGroup {
    id: FundSellOrderState
    label: string
    orders: FundSellOrderDto[]
    defaultExpanded: boolean
}

export default function FundSellOrderOverview() {
    const {t} = useTranslation()

    const fundSellOrders = useSelector(state => state.fundSellOrders)

    const [fundFilter, setFundFilter] = useState<FundDto | null>(null)
    const [customerFilter, setCustomerFilter] = useState<FundCustomerDto | null>(null)

    const funds = useSelector(state => state.funds)
    const fundCustomers = useSelector(state => state.fundCustomers)

    const filteredFundSellOrders = fundSellOrders
        .filter(fundSellOrder => !fundFilter || fundSellOrder.fundId === fundFilter.id)
        .filter(fundSellOrder => !customerFilter || fundSellOrder.depositorId === customerFilter.depositorId)

    const orderGroups: OrderGroup[] = [
        {id: 'AWAITING_SIGNATURE', label: t('pages.fundOverview.createdOrders'), orders: filteredFundSellOrders.filter(o => o.state === 'AWAITING_SIGNATURE'), defaultExpanded: true},
        {id: 'IN_PROGRESS', label: t('pages.fundOverview.signedOrders'), orders: filteredFundSellOrders.filter(o => o.state === 'IN_PROGRESS'), defaultExpanded: true},
        {id: 'EXECUTING_IN_MARKET', label: t('pages.fundOverview.executingInMarket'), orders: filteredFundSellOrders.filter(o => o.state === 'EXECUTING_IN_MARKET'), defaultExpanded: true},
        {id: 'TRANSFERRING_MONEY', label: t('pages.fundOverview.executedInMarket'), orders: filteredFundSellOrders.filter(o => o.state === 'TRANSFERRING_MONEY'), defaultExpanded: true},
        {id: 'COMPLETED', label: t('pages.fundOverview.completed'), orders: filteredFundSellOrders.filter(o => o.state === 'COMPLETED'), defaultExpanded: false},
        {id: 'CANCELLED', label: t('pages.fundOverview.cancelled'), orders: filteredFundSellOrders.filter(o => o.state === 'CANCELLED'), defaultExpanded: false},
        {id: 'CANCELLED_BY_DEPOSITOR', label: t('pages.fundOverview.cancelledByDepositor'), orders: filteredFundSellOrders.filter(o => o.state === 'CANCELLED_BY_DEPOSITOR'), defaultExpanded: false},
    ]

    return (
        <Fragment>
            <PageHeader title={t('pages.fundOverview.salesOrdersTitle')}/>
            <PageLayout>
                <>
                    <Box sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, gap: 1, mb: 2}}>
                        <Autocomplete
                            sx={{flex: 1}}
                            value={fundFilter}
                            options={funds}
                            onChange={(event, fund: FundDto | undefined) => setFundFilter(fund ?? null)}
                            autoHighlight
                            clearOnEscape
                            getOptionLabel={(option: FundDto) => option.name}
                            renderInput={(params) => <TextField label={t('pages.fundOverview.filterFund')} {...params} />}
                        />
                        <Autocomplete
                            sx={{flex: 1}}
                            value={customerFilter}
                            options={fundCustomers}
                            onChange={(event, fundCustomer: FundCustomerDto | undefined) => setCustomerFilter(fundCustomer ?? null)}
                            autoHighlight
                            clearOnEscape
                            getOptionLabel={(option: FundCustomerDto) => option.name}
                            renderInput={(params) => <TextField label={t('pages.fundOverview.filterCustomer')} {...params} />}
                        />
                    </Box>
                    {orderGroups.map(orderGroup => (
                        <Accordion key={orderGroup.id} defaultExpanded={orderGroup.defaultExpanded}>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                <Typography>{`${orderGroup.label} (${orderGroup.orders.length})`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FundSellOrderTable fundSellOrders={orderGroup.orders}/>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            </PageLayout>
        </Fragment>
    );
}
