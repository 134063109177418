import styles from './Paper.module.scss'
import classNames from 'classnames'
import HelpIcon from '#components/HelpIcon'
import { Box, Divider, Paper, Stack, SxProps, Typography } from '@mui/material'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'

/**
 *
 * @param {object} props
 * @param [props.id] {string}
 * @param [props.title] {string|React.ReactElement}
 * @param [props.supportCommonName] {string}
 * @param [props.needsAction] {boolean}
 * @param [props.className] {string}
 * @param [props.size] {'large'}
 * @param [props.visible] {boolean}
 * @param [props.transparent] {boolean}
 * @param [props.icon] {string}
 * @param [props.style] {object}
 * @param props.children
 * @param [props.titleActionElement]
 * @return {JSX.Element}
 */

function FxPaper({
    id,
    title,
    supportCommonName,
    needsAction,
    className,
    size,
    children,
    titleActionElement = null,
    icon,
    transparent = false,
    sx,
    ...filteredProps
} : {
    id?: string,
    title?: string | React.ReactElement,
    supportCommonName?: SupportCategoryNameType,
    needsAction?: boolean,
    className?: string,
    size?: 'large',
    transparent?: boolean,
    icon?: string,
    style?: object,
    children: React.ReactNode,
    titleActionElement?: React.ReactElement,
    sx?: SxProps
}) {
    return (
        <Paper
            id={id}
            sx={{p: 3, mb: 3, maxWidth: '100%', width: "80rem", ...sx}}
            className={classNames(
                className,
                transparent && styles.transparent,
                needsAction && styles.needsAction
            )}
            {...filteredProps}
        >
            { title && (
                <Stack spacing={1.5} mb={2}>
                    <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                        <Typography variant="h2" mb={0}>
                            {icon && <i className={icon}/> }
                            <span>{title}</span>
                        </Typography>
                        <Stack direction={"row"}>
                            {titleActionElement}
                            <Box height={"4rem"}>
                                { supportCommonName && (
                                    <HelpIcon supportCommonName={supportCommonName} />
                                )}
                            </Box>
                        </Stack>
                    </Stack>
                    <Divider />
                </Stack>
            )}
            {children}
        </Paper>
    )
}

export default FxPaper
