import { LoadingSpinner } from '#app/components';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import SupportUsers from '#pages/organizations/OrganizationDetail/SupportUsers/SupportUsers';
import useSupportPane from '#services/useSupportPane';
import { Button, Chip, Container, Divider, Drawer, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetSupportArticle } from './hooks/useGetSupportArticle';
import { useIsLoggedInToPayload } from './hooks/useIsLoggedInToPayload';
import { PayloadArticle } from './PayloadArticle/PayloadArticle';
import { SupportMainMenu } from './SupportMainMenu/SupportMainMenu';

function PayloadSupportPane() {
    const {t} = useTranslation()
    const supportPane = useSupportPane()
    const country = useCurrentCountryCode()
    const loggedInPayload = useIsLoggedInToPayload()
    const [searchParams, setSearchParams] = useSearchParams()
    const currentArticleId = searchParams.get('supportId')
    const currentArticle = useGetSupportArticle(currentArticleId)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const supportAdminLink = `https://fixrate.no/admin/collections/${country === "NO" ? "norwegian-support-articles" : "swedish-support-articles"}/${currentArticleId}`

    return (
        <Drawer 
            anchor={isMobile ? 'bottom' : 'right'}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '100%',
                    maxHeight: isMobile ? '80%' : '100%',
                },
            }}
            open={searchParams.has('supportId')} 
            onClose={supportPane.hide}>
            <Stack>
                <Stack px={3} py={1.5} direction="row" justifyContent="space-between">
                    <Typography variant="h3">
                        <i className="ri-lifebuoy-line"/>
                        <span>
                            {t('common.help')}
                        </span>
                    </Typography>
                    <IconButton onClick={supportPane.hide}>
                        <i className="ri-close-line"/>
                    </IconButton>
                </Stack>
                <Divider />
            </Stack>
            <Container sx={{width: "60rem", pt: 3, maxWidth: "100%"}}>
                { loggedInPayload && (
                    <Stack mb={4} flexDirection="row" justifyContent={"space-between"}>
                        <Chip icon={<i className="ri-check-line" />} label="Innlogget i Fixrate CMS" color="success" />
                        { currentArticle && (
                            <Button 
                                variant="outlined"
                                size="small"
                                startIcon={<i className="ri-edit-line" />}
                                onClick={() => window.open(supportAdminLink)}>
                                Rediger artikkel
                            </Button>
                        )}
                    </Stack>
                )}
                { supportPane.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    currentArticle ? (
                        <PayloadArticle article={currentArticle} />
                    ) : (
                        <SupportMainMenu />
                    )
                )}
                <SupportUsers/>
            </Container>
        </Drawer>
    )
}

export { PayloadSupportPane };

