import styles from './DepositDetailsBankInfo.module.css'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import {formatAccount, formatOrganizationNumber} from '#services/formatnumber'
import {CurrencyOutput, DateOutput, HelpIcon, InterestOutput, Paper} from '#components'
import {useTermination} from '#services/useTermination'
import {useTranslation} from 'react-i18next'
import {DepositDto} from '@fixrate/fixrate-query'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'

export default function DepositDetailsBankInfo({deposit}: { deposit: DepositDto }) {
    const {t} = useTranslation()

    const {firstValidTerminationDate} = useTermination()
    const organisationCountry = useCurrentCountryCode()

    const calculatedVolume = deposit.calculatedBalance
    const calculatedFinalVolume = deposit.calculatedFinalBalance
    let calculatedTotalVolume
    let calculatedTotalVolumeDate

    let interestRateDescription
    switch(deposit.product.type) {
        case "FIXED":
            interestRateDescription = t('common.productDescription.interestFixed')
            calculatedTotalVolume = deposit.estimatedTotalBalance
            calculatedTotalVolumeDate = deposit.estimatedDate
            break;
        case "FLOATING":
            interestRateDescription = t('common.productDescription.interestFloating', {benchmark: t('common.interestRateBenchmark.' + deposit.interestRateBenchmark)})
            calculatedTotalVolume = deposit.calculatedTotalBalance
            calculatedTotalVolumeDate = deposit.calculatedDate
            break;
    }

    let termsDescription
    switch (deposit.product.termsType) {
        case 'NOTICE':
            termsDescription = t('common.productDescription.termsNotice', {count: deposit.product.days})
            break
        case 'FIXED_TERMS':
            termsDescription = t('common.productDescription.termsFixed')
            break
        case 'RECURRING_FIXED_TERMS':
            termsDescription = t('common.productDescription.termsRecurring')
            break
    }

    return (
        <Paper title={t('pages-portfolio-bank.detailsTitle')}>
            <p>
                {interestRateDescription} {termsDescription} <HelpIcon supportCommonName='depositTermsOverview' inline helpContextPage="DepositDetailsBankInfo"/>
            </p>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-bank.depositConfirmedLabel')}><DateOutput.VerboseMonth date={deposit?.created}/></LabeledInfo>
                <LabeledInfo label={t('pages-portfolio-bank.depositStartedDateLabel')} hide={!deposit.startDate}><DateOutput.VerboseMonth date={deposit?.startDate}/></LabeledInfo>
                <LabeledInfo label={t('pages-portfolio-bank.terminationDateLabel')} hide={!deposit.terminationDate}><DateOutput.VerboseMonth date={deposit?.terminationDate}/></LabeledInfo>
                <LabeledInfo label={t('pages-portfolio-bank.accountNumberLabel')} info={formatAccount(deposit.account, organisationCountry)}/>
                <LabeledInfo label={t('pages-portfolio-bank.orgNoLabel')} info={formatOrganizationNumber(deposit.depositor.nationalIdentity, organisationCountry)}/>
                <LabeledInfo label={t('pages-portfolio-bank.emailLabel')} info={deposit.reportEmailAddress}/>
                {!!calculatedVolume && <LabeledInfo
                    label={t('pages-portfolio-bank.capitalizedBalanceLabel', {calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate)})}
                    info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(calculatedVolume)}
                    helpTextLabel={t('pages-portfolio-bank.capitalizedBalanceHelpText', {calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate)})}
                />}
                {!!calculatedTotalVolume && <LabeledInfo
                    label={t('pages-portfolio-bank.estimatedBalanceLabel', {calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate)})}
                    info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(calculatedTotalVolume)}
                    helpTextLabel={t('pages-portfolio-bank.estimatedBalanceHelpText', {calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate)})}
                />}
                {!!calculatedFinalVolume && <LabeledInfo
                    label={t('pages-portfolio-bank.endingBalanceLabel')}
                    info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(calculatedFinalVolume)}
                    helpTextLabel={t('pages-portfolio-bank.endingBalanceHelpText')}
                />}
                <LabeledInfo
                    label={t('pages-portfolio-bank.startBalanceLabel')}
                    info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(deposit.actualStartVolume || (deposit.volume * 1e6))}
                    helpTextLabel={t('pages-portfolio-bank.startBalanceHelpText')}
                />
                <LabeledInfo
                    show={deposit.product.type === 'FIXED'}
                    label={t('pages-portfolio-bank.effectiveInterestLabelWithoutDate')}
                    info={InterestOutput.format(deposit.effectiveInterestRate)}
                    helpTextLabel={t('pages-portfolio-bank.effectiveFixedInterestHelpText')}
                />
                <LabeledInfo
                    show={deposit.product.type !== 'FIXED' && !deposit.benchmarkInterestRateAvailable}
                    label={t('pages-portfolio-bank.effectiveInterestLabelWithoutDate')}
                    info={t('common.interestRateBenchmark.' + deposit.interestRateBenchmark) + ' + ' + InterestOutput.format(deposit.effectiveInterestRate)}
                    helpTextLabel={t('pages-portfolio-bank.effectiveFloatingInterestNoBenchmarkHelpText')}
                />
                <LabeledInfo
                    show={deposit.product.type !== 'FIXED' && deposit.benchmarkInterestRateAvailable}
                    label={t('pages-portfolio-bank.effectiveInterestLabel', {interestDate: DateOutput.formatDate(deposit.interestDate)})}
                    info={InterestOutput.format(deposit.effectiveInterestRate)}
                    helpTextLabel={t('pages-portfolio-bank.effectiveFloatingInterestHelpText', {benchmark: t('common.interestRateBenchmark.' + deposit.interestRateBenchmark)})}
                />
                <LabeledInfo
                    show={deposit.product.type === 'FIXED'}
                    label={t('pages-portfolio-bank.fixedInterestLabel')}
                    info={InterestOutput.format(deposit.nominalInterestRate)}
                />
                <LabeledInfo
                    show={!deposit.terminationDate && !deposit.terminationRequested && deposit.product.termsType === 'RECURRING_FIXED_TERMS'}
                    label={t('pages-portfolio-bank.interestUpdateLabel')}
                    helpTextLabel={t('pages-portfolio-bank.interestUpdateHelpText')}>
                    <DateOutput.VerboseMonth date={deposit.currentPeriod?.terminationDate}/>
                </LabeledInfo>
                <LabeledInfo
                    id="fixedInterestPeriodInterestRate"
                    label={t('pages-portfolio-bank.updatedInterestRateLabel')}
                    info={t('pages-portfolio-bank.updatedInterestRateInfo', {date: DateOutput.formatVerboseMonth(deposit.nextPeriod?.startDate), rate: InterestOutput.format(deposit.nextPeriod?.interestRate)})}
                    hide={!deposit.nextPeriod || !!deposit.terminationDate || !!deposit.terminationRequested}/>
                <LabeledInfo
                    label={t('pages-portfolio-bank.noticeLabel')}
                    hide={!!deposit.terminationDate || !!deposit.terminationRequested || deposit.product.termsType !== 'RECURRING_FIXED_TERMS'}
                    helpTextLabel={t('pages-portfolio-bank.noticeHelpText')}>
                    {t('pages-portfolio-bank.noticeInfo', {date: DateOutput.formatVerboseMonth(firstValidTerminationDate(deposit))})}
                </LabeledInfo>
                <LabeledInfo
                    label={t('pages-portfolio-bank.interestMarginLabel')}
                    info={InterestOutput.format(deposit.nominalInterestRate)}
                    hide={deposit.product.type === 'FIXED'}/>
                <LabeledInfo
                    label={t('pages-portfolio-bank.interestRateConvention')}
                    info={t('common.interestRateConvention.' + deposit.interestRateConvention)}
                    helpTextLabel={t('common.interestRateConventionHelpText')}/>
                <LabeledInfo
                    id="interestRateChangedInfo"
                    label={t('pages-portfolio-bank.updatedInterestMarginLabel')}
                    info={t('pages-portfolio-bank.updatedInterestMarginInfo', {date: DateOutput.formatVerboseMonth(deposit?.newInterestRateChangeDate), margin: InterestOutput.format(deposit.newNominalInterestRate)})}
                    hide={!deposit.newNominalInterestRate}/>
                <LabeledInfo
                    id="extensionInterestRateInfo"
                    label={t('pages-portfolio-bank.updatedInterestRateLabel')}
                    info={t('pages-portfolio-bank.updatedInterestRateInfo', {date: DateOutput.formatVerboseMonth(deposit.extensionStartDate), rate: InterestOutput.format(deposit.extensionInterestRate)})}
                    hide={deposit.extensionOfferState !== 'ACCEPTED'}/>
                <LabeledInfo
                    id="settlementAccountInfo"
                    label={t('pages-portfolio-bank.settlementAccountLabel')}
                    info={formatAccount(deposit.settlementAccount, organisationCountry)}
                    hide={!deposit.settlementAccount}/>
                <LabeledInfo
                    label={t('pages-portfolio-bank.settlementAccountIbanLabel')}
                    info={deposit.settlementAccountIban}
                    hide={!deposit.settlementAccountIban}/>
                <LabeledInfo
                    label={t('pages-portfolio-bank.settlementAccountBicLabel')}
                    info={deposit.settlementAccountBic}
                    hide={!deposit.settlementAccountBic}/>
                <LabeledInfo
                    label={t('pages-portfolio-bank.extendableFromLabel')}
                    hide={!deposit.extendableFrom}>
                    <DateOutput.VerboseMonth date={deposit?.extendableFrom}/>
                </LabeledInfo>
            </div>
            <p className={styles.subtext}>{t('pages-portfolio-bank.subText')}</p>
        </Paper>
    )
}
