import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import {useDispatch} from 'react-redux'
import {FundSellOrderRow} from './FundSellOrderTable'
import {showConfirmationModal} from '#state/reducers/confirmationModal'
import {FundNav, FundSellOrderState, FundTradeOrderStatusDto} from '@fixrate/fixrate-query'
import {CurrencyOutput, NumberInput} from '#components'
import {useCommand} from '#command'
import DatePicker from '#components/DatePicker'
import format from 'date-fns/format'
import {useTranslation} from 'react-i18next'
import {useSelector} from '#state/useSelector'
import {useFieldState} from '@fixrate/fieldstate'
import {BigNumber} from 'bignumber.js'
import {ConfirmationIconName} from "#app/layers/ConfirmationModal/ConfirmationIcon";
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput';

export interface WithdrawalResponse {
    resultStatus: "SUCCESS" | "FAILURE" | "DUPLICATE" | "INSUFFICIENT_FUNDS" | "NO_MATCHING_SETTLEMENT_ACCOUNT"
    description?: string
}

const DetailRow = ({children, title} : {children: React.ReactNode, title: string}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', borderBottom: 'lightgray 1px solid', marginBottom: 4}}>
            <Typography variant={'body1'}>{title}:</Typography>
            {children}
        </Box>
    )
}

type StateTexts = {
    label: string
    description: string
}
const fundSellOrderStatesMap: { [K in FundSellOrderState]: StateTexts } = {
    AWAITING_SIGNATURE: {
        label: 'Ordre opprettet',
        description: 'Avventer signatur fra kunde.',
    },
    IN_PROGRESS: {
        label: 'Ordre signert',
        description: 'Avventer at salgsordren sendes til markedet.',
    },
    EXECUTING_IN_MARKET: {
        label: 'Ordren er i markedet',
        description: 'Avventer at salget blir utført i markedet.',
    },
    TRANSFERRING_MONEY: {
        label: 'Salget er utført i markedet',
        description: 'Avventer at penger overføres til kunde.',
    },
    COMPLETED: {
        label: 'Fullført',
        description: '',
    },
    CANCELLED: {
        label: 'Avbrutt',
        description: '',
    },
    CANCELLED_BY_DEPOSITOR: {
        label: 'Avbrutt av innskyter',
        description: '',
    },
}

export default function UpdateStatusDialog({fundSellOrderRow, onClose}: { fundSellOrderRow: FundSellOrderRow | null, onClose: () => void }) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {cancelFundSellOrder, completeFundSellOrder, registerFundSellOrderExecution, registerFundSellOrderExecutingInMarket} = useCommand()
    const [submittingWithdrawal, setSubmittingWithdrawal] = useState(false)

    const [selectedState, setSelectedState] = useState<FundSellOrderState | ''>(fundSellOrderRow?.state ?? '')

    const fund = useSelector(state => state.funds.find(f => f.id === fundSellOrderRow?.fundId))
    const fundShareClass = useSelector(state => state.funds.flatMap(f => f.fundShareClasses).find(fsc => fsc.id === fundSellOrderRow?.fundShareClassId))
    const decimalPrecision = fund?.decimalPrecision ?? 4

    const [fundNav, setFundNav] = useState<BigNumber>(null)
    const [fundAccrual, setFundAccrual] = useState<BigNumber>(null)

    const unitQuantityField = useFieldState<number | null>(fundSellOrderRow?.unitQuantity ?? null, ({value, isEditing}) => value=== null?"Antall enheter må fylles ut":"")
    const unitPriceField = useFieldState<number | null>(fundSellOrderRow?.unitPrice ?? null, ({value, isEditing}) => {
        if (!isEditing && value !== null && fundNav !== null) {

            if (!new BigNumber(value).eq(fundNav))
                return 'Fondets NAV er ikke likt oppgitt enhetspris'
        }
        return null
    })
    const accrualField = useFieldState<number | null>(null, ({value, isEditing}) => {
        if (fund?.isDividendFund && value === null) {
            return 'Rentekurs må fylles ut'
        }
        return ''
    })

    const roundingErrorField = useFieldState<number | null>(fundSellOrderRow?.roundingError ?? null, ({value, isEditing}) => value==null?"Avrunding må fylles ut":"")
    const amountField = useFieldState<number | null>(fundSellOrderRow?.amount ?? null, ({value, isEditing}) => {
        if (!isEditing && value !== null) {
            const amount = new BigNumber(value)
            const calculatedAmount = calculateValue().dp(2, BigNumber.ROUND_HALF_UP).plus(new BigNumber(roundingErrorField.value))

            if (!calculatedAmount.eq(amount)) {
                return `Beløp er ikke likt enhetspris * antall enheter + avrunding = ${calculatedAmount})`
            }
        }

        if (value === null) {
            return 'Beløp må fylles ut'
        }
        return ''
    })

    const [settlementDate, setSettlementDate] = useState<Date | null>(fundSellOrderRow?.settlementDate ? new Date(fundSellOrderRow.settlementDate) : null)
    const [transactionDate, setTransactionDate] = useState<Date | null>(fundSellOrderRow?.transactionDate ? new Date(fundSellOrderRow.transactionDate) : null)


    const [submitting, setSubmitting] = useState<boolean>(false)
    const [statusSubmitting, setStatusSubmitting] = useState<boolean>(false)

    const fundCustomers = useSelector(state => state.fundCustomers)
    const fundCustomer = fundCustomers.find(fc => fc.depositorId === fundSellOrderRow?.depositorId)
    const settlementAccount = fundCustomer?.settlementAccounts?.find(sa => sa?.id === fundSellOrderRow?.settlementAccountId)

    function calculateValue(): BigNumber {
        const unitQuantity = new BigNumber(unitQuantityField.value ?? 0)
        const unitPrice = new BigNumber(unitPriceField.value ?? 0)
        return unitQuantity.multipliedBy(unitPrice)
    }

    // Gets stable instances of the set-functions
    // The *.fn objects are not stable, since the validators depends on mutable data
    const setUnitQuantityField = unitQuantityField.fn.setValue
    const setUnitPriceField = unitPriceField.fn.setValue
    const setAccrualField = accrualField.fn.setValue
    const setRoundingErrorField = roundingErrorField.fn.setValue
    const setAmountField = amountField.fn.setValue

    // fetch Nav from /api/fund/nav/{fundId}?date={date} on change of transactionDate
    useEffect(() => {
        async function fetchNav() {
            if (fund && transactionDate) {
                const navResponse = await fetch(`/api/fund/nav/${fundShareClass.id}?date=${format(transactionDate, 'yyyy-MM-dd')}`, {
                    credentials: 'include',
                })

                if (navResponse.ok) {

                    const nav = await navResponse.json() as FundNav
                    setFundNav(new BigNumber(nav.nav))
                    setFundAccrual(new BigNumber(nav.accrual))
                    setAccrualField(nav.accrual)
                } else {
                    setFundNav(null)
                    setFundAccrual(null)
                    setAccrualField(null)
                }
            }
        }

        fetchNav()
    }, [fund, fundShareClass?.id, setAccrualField, transactionDate])

    useEffect(() => {
        setSelectedState(fundSellOrderRow?.state ?? '')
        setUnitQuantityField(fundSellOrderRow?.unitQuantity ?? null)
        setUnitPriceField(fundSellOrderRow?.unitPrice ?? null)
        setAmountField(fundSellOrderRow?.amount ?? null)
        setRoundingErrorField(fundSellOrderRow?.roundingError ?? null)
        setSettlementDate(fundSellOrderRow?.settlementDate ? new Date(fundSellOrderRow.settlementDate) : null)
        setTransactionDate(fundSellOrderRow?.transactionDate ? new Date(fundSellOrderRow.transactionDate) : null)
    }, [fundSellOrderRow, setAmountField, setRoundingErrorField, setUnitPriceField, setUnitQuantityField])

    const handleUpdateStatusFromFA = async () => {
        setStatusSubmitting(true)
        try {
            const statusResponse = await fetch(`/api/fund/fund-sell-order-status/${fundSellOrderRow?.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
            })

            const status = await statusResponse.json() as FundTradeOrderStatusDto

            if (status.cancelled) {
                setSelectedState('CANCELLED')
            } else if (status.executingInMarket) {
                setSelectedState('EXECUTING_IN_MARKET')
            } else if (status.fulfilled) {
                setSelectedState('TRANSFERRING_MONEY')
                amountField.setValue(status.amount)
                unitPriceField.setValue(status.unitPrice)
                unitQuantityField.setValue(status.unitQuantity)
                roundingErrorField.setValue(status.roundingError)
                setSettlementDate(new Date(status.settlementDate))
                setTransactionDate(new Date(status.transactionDate))
            }


        } finally {
            setStatusSubmitting(false)
        }
    }

    async function handleSubmittingWithdrawal() {
        setSubmittingWithdrawal(true)
        const cid = fundCustomer?.portfolios.find(p => p?.currency === fundShareClass?.currency)?.fundData.cid
        const faPortfolioId = cid?.substring(0, cid?.length - 1)
        const reqBody = {
            fundSellOrderId: fundSellOrderRow.id,
            amount: amountField.value,
            description: "Utbetaling fra fondssalg",
            transactionDate: format(transactionDate, 'yyyy-MM-dd'),
            settlementDate: format(settlementDate, 'yyyy-MM-dd'),
            settlementAccountNumber: settlementAccount.accountNumber
        }
        try {
            const withdrawalResp = await fetch(`/api/fund-actions/portfolio/${faPortfolioId}/withdrawal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(reqBody)
            })
            const withdrawalResponse = await withdrawalResp.json() as WithdrawalResponse
            dispatch(showConfirmationModal(getModalPayloadForWithdrawalResponse(withdrawalResponse)))
        } catch (error) {
            dispatch(showConfirmationModal({
                title: 'Utbetaling feilet: Ukjent feil',
                text: 'En ukjent feil oppsto som gjorde at utbetalingen ikke kunne gjennomføres i FA',
                buttonText: t('common.continue'),
                confirmationIconName: 'error',
            }))
        } finally {
            onClose()
            setSubmittingWithdrawal(false)
        }
    }

    function getModalPayloadForWithdrawalResponse(withdrawalResponse: WithdrawalResponse): {title: string, text: string, buttonText: string, confirmationIconName?: ConfirmationIconName} {
        switch (withdrawalResponse.resultStatus) {
            case "SUCCESS":
                return {
                    title: 'Utbetaling bekreftet!',
                    text: `Utbetaling er registrert i FA. extId: ${fundSellOrderRow.id}`,
                    buttonText: t('common.continue'),
                }
            case "INSUFFICIENT_FUNDS":
                return {
                    title: 'Utbetaling feilet: Ikke nok penger på konto',
                    text: 'Klientkonto i FA har for få midler til å gjennomføre utbetalingen',
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
            case "DUPLICATE":
                return {
                    title: 'Utbetaling feilet: Duplikat',
                    text: `Det finnes allerede en utbetaling registrert på denne ordren. Sjekk i FA for extId: ${fundSellOrderRow.id}`,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
            case "NO_MATCHING_SETTLEMENT_ACCOUNT":
                return {
                    title: 'Utbetaling feilet: Uventet utbetalingskonto',
                    text: `Finner ingen utbetalingskonto i FA som matcher utbetalingskonto for salgsordren: ${settlementAccount?.accountNumber}`,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
            case "FAILURE":
            default:
                return {
                    title: 'Utbetaling feilet: Ukjent feil',
                    text: 'Feilmelding: ' + (withdrawalResponse.description ?? 'Ukjent feil oppsto under utbetalingen i FA'),
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
        }
    }

    function currentSelectableStates(): FundSellOrderState[] {
        if (!fundSellOrderRow.registeredInFa) {
            return []
        }
        const currentState = fundSellOrderRow.state
        switch (currentState) {
            case 'AWAITING_SIGNATURE':
                return ['AWAITING_SIGNATURE']
            case 'IN_PROGRESS':
                return ['IN_PROGRESS', 'EXECUTING_IN_MARKET', 'CANCELLED']
            case 'EXECUTING_IN_MARKET':
                return ['EXECUTING_IN_MARKET', 'TRANSFERRING_MONEY', 'CANCELLED']
            case 'TRANSFERRING_MONEY':
                return ['TRANSFERRING_MONEY', 'COMPLETED', 'CANCELLED']
            case 'COMPLETED':
                return ['COMPLETED']
            case 'CANCELLED':
                return ['CANCELLED']
            case 'CANCELLED_BY_DEPOSITOR':
                return ['CANCELLED_BY_DEPOSITOR']
            default:
                return []
        }
    }

    function isDisabled(state: FundSellOrderState): boolean {
        return !currentSelectableStates().includes(state)
    }

    function isSubmittingWithdrawalDisabled() {
        return submittingWithdrawal || fundSellOrderRow?.state !== 'TRANSFERRING_MONEY'
    }

    async function handleSubmit() {
        if (!fundSellOrderRow) return
        if (isDisabled(selectedState as FundSellOrderState)) return

        setSubmitting(true)

        switch (selectedState) {
            case 'IN_PROGRESS': {
                dispatch(showConfirmationModal({
                    title: 'Dette går ikke an enda, hehe :)',
                    text: 'Dette går ikke an enda, hehe :)',
                    buttonText: t('common.continue'),
                }))
                break
            }
            case 'CANCELLED': {
                const confirmed = window.confirm('Er du sikker på at du vil avbryte denne ordren?')
                if (!confirmed) break

                const {waitForCommand} = await cancelFundSellOrder(fundSellOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Ordren er avbrutt!',
                        text: 'Ordren er avbrutt!',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
            case 'EXECUTING_IN_MARKET': {
                const {waitForCommand} = await registerFundSellOrderExecutingInMarket(fundSellOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Status oppdatert!',
                        text: 'Status ble satt som: "Ordren er i markedet"',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
            case 'TRANSFERRING_MONEY': {
                unitPriceField.validate()
                unitQuantityField.validate()
                roundingErrorField.validate()
                amountField.validate()
                if (transactionDate === null || settlementDate === null || !unitQuantityField.valid || !unitPriceField.valid || !amountField.valid || !roundingErrorField.valid) {
                    setSubmitting(false)
                    return
                }

                const {waitForCommand} = await registerFundSellOrderExecution(
                    fundSellOrderRow.id,
                    unitQuantityField.value,
                    unitPriceField.value,
                    format(transactionDate, 'yyyy-MM-dd'),
                    format(settlementDate, 'yyyy-MM-dd'),
                    amountField.value,
                    roundingErrorField.value,
                )

                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Registering fullført',
                        text: 'Registering fullført',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
            case 'COMPLETED': {
                const {waitForCommand} = await completeFundSellOrder(fundSellOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(showConfirmationModal({
                        title: 'Ordre fullført!',
                        text: 'Ordre fullført!',
                        buttonText: t('common.continue'),
                    }))
                    onClose()
                }
                break
            }
        }

        setSubmitting(false)
    }

    return (
        <Dialog open={fundSellOrderRow !== null} onClose={onClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>Sett status</DialogTitle>
            {fundSellOrderRow && (
                <DialogContent>
                    <DialogContentText sx={{mb: 1}}>Status skal i utgangspunktet oppdateres automatisk fra FA, men dersom dette ikke har skjedd kan du overstyre her.</DialogContentText>
                    <DialogContentText sx={{mb: 2}}>Det er FA som er fasit, men det er denne statusen som kunden vil se i Fixrate.</DialogContentText>
                    <DetailRow title={'Fond'}>{fundSellOrderRow.fundName}</DetailRow>
                    <DetailRow title={'Innskyter'}>{fundSellOrderRow.depositorName}</DetailRow>
                    <DetailRow data-cy="sellOrderStatusQuantityText" title={'Andeler'}>
                        {CurrencyOutput.formatNoCode(fundSellOrderRow.unitQuantity, decimalPrecision)}
                    </DetailRow>
                    <DetailRow title={'External ID'}>{fundSellOrderRow.id}</DetailRow>
                    <FormControl sx={{mt: 2}}>
                        <FormLabel>Ordrestatus</FormLabel>
                        <RadioGroup data-cy="statusSelectRadio" value={selectedState}>
                            {Object.entries(fundSellOrderStatesMap).map(([state, texts]) => (
                                <div key={state}>
                                    <FormControl margin={'normal'} disabled={isDisabled(state as FundSellOrderState)}>
                                        <FormControlLabel
                                            value={state}
                                            control={<Radio/>}
                                            slotProps={state === fundSellOrderRow.state && {typography: {fontWeight: 700}}}
                                            label={`${texts.label}${state === fundSellOrderRow.state ? ' (nåværende)' : ''}`}
                                            onChange={() => setSelectedState(state as FundSellOrderState)}
                                        />
                                        <FormHelperText>{texts.description}</FormHelperText>
                                    </FormControl>

                                    {state === 'TRANSFERRING_MONEY' && selectedState === 'TRANSFERRING_MONEY' && (
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mb: 2}}>

                                            <DatePicker
                                                id="transactionDateInput"
                                                selected={transactionDate}
                                                onChange={date => setTransactionDate(date)}
                                                customInput={<TextField label={'Transaksjonsdato'}
                                                                        helperText={!transactionDate && 'Transaksjonsdato må fylles ut'}
                                                                        error={!transactionDate}

                                                />}
                                            />
                                            <DatePicker
                                                id="settlementDateInput"
                                                selected={settlementDate}
                                                onChange={date => setSettlementDate(date)}
                                                customInput={<TextField label={'Bokføringsdato'}
                                                                        helperText={!settlementDate && 'Bokføringsdato må fylles ut'}
                                                                        error={!settlementDate}

                                                />}
                                            />
                                            <NumberInput
                                                label={'Antall solgte andeler'}
                                                value={unitQuantityField.value}
                                                onChange={unitQuantityField.setValue}
                                                formatFn={v => CurrencyOutputObject(v, {withCurrency: false, maximumDecimals: 10})}
                                                error={!unitQuantityField.valid}
                                                onBlur={unitQuantityField.onBlur}
                                                helperText={unitQuantityField.errorMessage}
                                                data-cy={'unitQuantityInput'}
                                            />


                                            <NumberInput
                                                label={'Andelspris'}
                                                value={unitPriceField.value}
                                                onChange={unitPriceField.setValue}
                                                formatFn={v => CurrencyOutputObject(v, {currency: fundShareClass?.currency, maximumDecimals: 10})}
                                                error={!unitPriceField.valid}
                                                onBlur={unitPriceField.onBlur}
                                                helperText={(fundNav && transactionDate && 'Fondet har ' + fundNav.toString() + ', den ' + format(transactionDate, 'dd.MM.yyyy')) || (transactionDate && 'Fondet har ikke NAV for ' + format(transactionDate, 'dd.MM.yyyy') + '. Oppgitt NAV vil bli brukt.')}
                                                data-cy={'unitPriceInput'}
                                            />

                                            {fund.dividendFund && <NumberInput
                                                disabled={true}
                                                label={'Fondets rentekurs pr andel'}
                                                placeholder={'test'}
                                                value={accrualField.value}
                                                onChange={accrualField.setValue}
                                                formatFn={v => CurrencyOutputObject(v, {currency: fundShareClass?.currency, maximumDecimals: 10})}
                                                onBlur={accrualField.onBlur}
                                                helperText={(fundAccrual && transactionDate && 'Fondet har ' + fundAccrual.toString() + ', den ' + format(transactionDate, 'dd.MM.yyyy')) || (transactionDate && 'Fondet har ikke rentekurs for ' + format(transactionDate, 'dd.MM.yyyy') + '. Oppgitt rentekurs vil bli brukt.')}
                                                data-cy={'accrualInput'}
                                            />}
                                            <p>Handelsbeløp {CurrencyOutputObject(calculateValue().dp(2, BigNumber.ROUND_HALF_UP).toNumber(), { maximumDecimals: 2 })}</p>

                                            <NumberInput
                                                label={'Avrundingskorrigering'}
                                                value={roundingErrorField.value}
                                                onChange={roundingErrorField.setValue}
                                                formatFn={v => CurrencyOutputObject(v, {currency: fundShareClass?.currency, maximumDecimals: 10})}
                                                error={!roundingErrorField.valid}
                                                onBlur={roundingErrorField.onBlur}
                                                helperText={roundingErrorField.errorMessage}
                                                data-cy={'roundingErrorInput'}
                                            />

                                            <NumberInput
                                                label={'Overført beløp'}
                                                value={amountField.value}
                                                onChange={amountField.setValue}
                                                formatFn={v => CurrencyOutputObject(v, { currency: fundShareClass?.currency, maximumDecimals: 10 })}
                                                error={!amountField.valid}
                                                onBlur={amountField.onBlur}
                                                helperText={amountField.errorMessage}
                                                data-cy={'amountInput'}
                                            />

                                        </Box>
                                    )}
                                </div>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            )}
            <DialogActions>
                <Button disabled={statusSubmitting} onClick={handleUpdateStatusFromFA}>Hent inn status fra FA</Button>
                <Button data-cy="cancelButton" onClick={onClose}>Avbryt</Button>
                <LoadingButton
                    data-cy="updateStatusButton"
                    variant={'contained'}
                    disabled={submitting || selectedState === fundSellOrderRow?.state}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    Oppdater status
                </LoadingButton>
                <LoadingButton
                    data-cy="withdrawButton"
                    variant={'contained'}
                    disabled={isSubmittingWithdrawalDisabled()}
                    loading={submittingWithdrawal}
                    onClick={handleSubmittingWithdrawal}
                >
                    Utfør utbetaling i FA
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
