import config from '#app/config'
import { useCommand } from '#command'
import { ActionBlock, DateOutput, HelpIcon } from '#components'
import Button from '#components/Button'
import { useTranslation } from '#components/i18n'
import { useSelector } from '#state/useSelector'
import { InterestRateChangeDto } from '@fixrate/fixrate-query'
import { useNavigate } from 'react-router-dom'
import DocumentIcon from '../../common/icons/DocumentIcon'
import styles from './InterestRateChangeBankDetails.module.css'

const API_BASE_URL = config().apiUrl

type Props = {
    interestRateChange: InterestRateChangeDto
}

export default function NotSentToDepositor({interestRateChange}: Props) {
    const {t} = useTranslation('pages-interestratechange-bank')
    const {cancelInterestRateChange} = useCommand()
    const navigate = useNavigate()
    const document = useSelector(state => interestRateChange && interestRateChange.documentId && state.documents[interestRateChange.documentId])

    async function onSubmit() {
        const {waitForCommand} = await cancelInterestRateChange(interestRateChange.id, '')
        const success = await waitForCommand()
        if (success) {
            navigate('/interestratechange-bank/')
        }
    }

    if (!document || !interestRateChange) return null

    return (
        <ActionBlock header={t('tooLateActionHeader')}>
            <p>
                {t('tooLateInfo1', {date: DateOutput.formatDate(interestRateChange.sendDeadline)})} <HelpIcon
                supportCommonName="interestRateChange" inline={true}/>
            </p>
            <p>
                {t('tooLateInfo2')}
            </p>
            <p className={styles.documentlink}>
                <DocumentIcon className={styles.icon}/>
                <a href={API_BASE_URL + document.link}>{t('documentName')}</a>
            </p>

            <Button onClick={onSubmit}>
                {t('tooLateButtonText')}
            </Button>
        </ActionBlock>
    )
}
