import {Button, ButtonGroup, Grow, Stack, SxProps} from '@mui/material'
import {useRef} from 'react'

interface Props {
    firstName: string,
    lastName: string,
    sx?: SxProps,
    securityRole: string
}

export default function PartnerAdvisorBadge({firstName, lastName, sx, securityRole}: Props) {
    const anchorRef = useRef<HTMLDivElement>(null)

    return (
        <Stack component="li" spacing={0.5} sx={sx}>
            <Grow in={true} timeout={800}>
                <ButtonGroup
                    variant="contained"
                    color="backgroundWhite"
                    ref={anchorRef}
                    data-cy-security-role={securityRole}
                    sx={{'& .MuiButtonGroup-firstButton': {borderColor: 'rgba(0,0,0,0.1)'}}}>
                    <Button
                        sx={{fontSize: '1.4rem'}}
                        disabled={true}
                        startIcon={<i className="ri-account-circle-line"/>}>
                        <Stack alignItems={'flex-start'}>
                            <span data-cy="badge-name">{firstName + ' ' + lastName}</span>
                        </Stack>
                    </Button>
                </ButtonGroup>
            </Grow>
        </Stack>
    )
}
