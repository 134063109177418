import FundPlacementDetails from '#pages/portfolio-depositor/FundPlacementDetails/FundPlacementDetails'
import FundTransactions from '#pages/portfolio-depositor/FundTransactions/FundTransactions'
import { Navigate, Route, Routes } from 'react-router-dom'
import DepositDetails from './DepositDetails/DepositDetails'
import Portfolio from './Portfolio'

export default function PortfolioDepositor() {
    return (
        <Routes>
            <Route path='' element={<Navigate to={{ pathname: '/portfolio/overview' }} />} />
            <Route path='overview/:tab' element={<Portfolio/>} />
            <Route path='overview/:tab/:fundTab' element={<Portfolio/>} />
            <Route path='overview' element={<Portfolio/>} />
            <Route path='funds/:fundPlacementId' element={<FundPlacementDetails/>} />
            <Route path='funds' element={<FundPlacementDetails/>} />
            <Route path='id/:depositId/:command' element={<DepositDetails/>} />
            <Route path='id/:depositId' element={<DepositDetails/>} />
            <Route path='id' element={<DepositDetails/>} />
            <Route path='fund-transactions' element={<FundTransactions/>} />
            {/*Redirecting to new route /reports */}
            <Route path='monthly-reports' element={<Navigate to={{ pathname: '/reports/deposits/interest-balance'}} />} />
            <Route path='annual-statements' element={<Navigate to={{ pathname: '/reports/deposits/annual-statements' }} />} />
            <Route path='fund-reports' element={<Navigate to={{ pathname: '/reports/funds' }} />} />
            <Route path='fund-reports/accounting' element={<Navigate to={{ pathname: '/reports/funds/accounting' }} />} />
            <Route path='fund-reports/inventory' element={<Navigate to={{ pathname: '/reports/funds/inventory' }} />} />
        </Routes>
    )
}
