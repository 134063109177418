import AsyncButton from '#app/components/Button/AsyncButton'
import { CompanyInfo, useWarningMessageOnRegisterCompany } from '#app/components/CompanySelect/CompanySelect'
import { useConfirmationModal } from '#app/layers/ConfirmationModal/ConfirmationModal'
import { useCommand } from '#command'
import { ButtonRow } from '#components'
import CompanySelect from '#components/CompanySelect'
import DateInput from '#components/DateInput'
import Modal from '#components/Modal'
import restEndpoint from '#services/rest/rest-endpoint'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { validateEmailAddress } from '#services/validateFields'
import { useSelector } from '#state/useSelector'
import { Alert, Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import format from 'date-fns/format'
import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from './CustomerInvite.module.scss'
import RegionalRegionSelector from '#pages/Customers/CustomerOverview/RegionalRegionSelector'

export default function CustomerInvite({ visible, hide }: { visible: boolean; hide: () => void }) {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const [regionalRegion, setRegionalRegion] = useState<'NO' | 'SE'>('NO')
    const [company, setCompany] = useState<CompanyInfo | null>(null)
    const session = useSelector((state) => state.session)
    const [companyError, setCompanyError] = useState('')
    const partner = useSelector((state) => state.partner)
    const confirmationModal = useConfirmationModal()
    const [customerHasAcceptedRegistration, setCustomerHasAcceptedRegistration] = useState<boolean>(true)

    const firstNameValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteFirstNameMissing'))
    const firstName = useFieldState('', firstNameValidator)

    const lastNameValidator = useStableValidator<string>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteLastNameMissing'))
    const lastName = useFieldState('', lastNameValidator)


    const emailAddressValidator = useCallback(({ value, isEditing }) => {
        if (!value) {
            return t('blocks-UsersList.inviteEmailMissing')
        }

        if (!isEditing) {
            if (!validateEmailAddress(value)) {
                return t('blocks-UsersList.inviteEmailInvalid')
            }
        }
    }, [t])
    const email = useFieldState('', emailAddressValidator)

    const birthDateValidator = useStableValidator<Date>('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteDateOfBirthInvalid'))

    const birthDate = useFieldState(null, birthDateValidator)

    const { sendPartnerInvite } = useCommand()

    async function onSubmit() {

        console.log('onSubmit', company, customerHasAcceptedRegistration)
        if (!company) {
            setCompanyError(t('pages-customers.inviteCompanySelectValidation'))
        } else {
            const exists = await restEndpoint(dispatch).getDepositorRegistrationStatus(company.nationalIdentity)
            if (customerHasAcceptedRegistration) {
                const { waitForCommand } = await sendPartnerInvite(
                    partner?.id,
                    company.nationalIdentity,
                    company.nationality,
                    customerHasAcceptedRegistration
                )
                const success = await waitForCommand()
                if (success) {
                    confirmationModal(
                        t('pages-customers.registerConfirmationHeading'),
                        t('pages-customers.registerConfirmationMessage', { companyName: company.name }),
                        t('common.ok')
                    )
                    resetForm()
                }
            } else if (exists) {
                const { waitForCommand } = await sendPartnerInvite(
                    partner?.id,
                    company.nationalIdentity,
                    company.nationality,
                    customerHasAcceptedRegistration
                )
                const success = await waitForCommand()
                if (success) {
                    confirmationModal(
                        t('pages-customers.inviteConfirmationHeading'),
                        t('pages-customers.inviteConfirmationMessageExists', { companyName: company.name }),
                        t('common.ok')
                    )
                    resetForm()
                }
            } else {
                console.log('validate', birthDate)
                const valid = firstName.validate() && lastName.validate() && email.validate() && birthDate.validate()
                if (valid) {
                    const { waitForCommand } = await sendPartnerInvite(
                        partner?.id,
                        company.nationalIdentity,
                        company.nationality,
                        customerHasAcceptedRegistration,
                        firstName.value,
                        lastName.value,
                        email.value,
                        format(birthDate.value, 'yyyy-MM-dd')
                    )
                    const success = await waitForCommand()
                    if (success) {
                        confirmationModal(
                            t('pages-customers.inviteConfirmationHeading'),
                            t('pages-customers.inviteConfirmationMessage'),
                            t('common.ok')
                        )
                        resetForm()
                    }
                }
            }
        }
    }
    const getWarningMessage = useWarningMessageOnRegisterCompany(company, session)

    function resetForm() {
        setCompany(null)
        setCompanyError('')
        firstName.reset()
        lastName.reset()
        email.reset()
        birthDate.reset()
        hide()
    }

    return (
        visible && (
            <Modal header={t('pages-customers.inviteCustomerHeading')} onCancel={resetForm}>
                <p>{t('pages-customers.inviteCustomerMessage')}</p>
                <RegionalRegionSelector value={regionalRegion} onChange={setRegionalRegion}/>
                <label className='flex'>{regionalRegion === 'NO' ? t('pages-customers.companyName') : t('pages-customers.organisationNumber')}</label>
                <CompanySelect
                    onChange={setCompany}
                    setErrorMessage={setCompanyError}
                    placeholder={t('common.search')}
                    regulatoryRegion={regionalRegion}
                />
                <p className='field-error-message'>{companyError}</p>
                {getWarningMessage && (
                    <Alert sx={{ mt: 1 }} severity='info'>
                        {getWarningMessage}
                    </Alert>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            id={'customerHasAcceptedRegistration'}
                            checked={customerHasAcceptedRegistration}
                            onChange={(e) => setCustomerHasAcceptedRegistration(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={t('blocks-UsersList.customerHasAcceptedRegistration', { partnerName: partner?.name })}
                />

                {customerHasAcceptedRegistration && partner?.partnerCategory !== 'SUPPORT' && (
                    <p className={styles.registerContactMessage}>
                        Hvis bedriften allerede er registrert på Fixrate, vil administrator for bedriften motta en
                        e-post med spørsmål bekrefte at de godtar at {partner?.name} kan behandle personopplysninger på
                        vegne av bedriften og får innsyn i bedriftens portefølje.
                    </p>
                )}

                {customerHasAcceptedRegistration && partner?.partnerCategory === 'SUPPORT' && (
                    <p className={styles.registerContactMessage}>Invitasjonen vil automatisk bli godkjent.</p>
                )}

                {!customerHasAcceptedRegistration && (
                    <Fragment>
                        <p className={styles.registerContactMessage}>
                            Du må registrere en kontaktperson. Kontaktpersonen vil motta en e-post med en invitasjon til
                            å registrere seg og bekrefte at de godtar at {partner?.name} kan behandle personopplysninger
                            på vegne av bedriften og får innsyn i bedriftens portefølje.
                        </p>
                        <p className={styles.registerContactMessage}>
                            Hvis bedriften allerede er registrert på Fixrate, vil denne e-posten sendes til
                            administrator for bedriften.
                        </p>
                        <label className={'flex'} htmlFor='firstNameField'>
                            {t('blocks-UsersList.inviteFirstName')}
                        </label>
                        <TextField
                            id='firstNameField'
                            onBlur={firstName.onBlur}
                            onChange={(e) => firstName.setValue(e.target.value)}
                            type='text'
                            value={firstName.value}
                        />
                        <p className='field-error-message'>{!firstName.valid && firstName.errorMessage}</p>

                        <label className={'flex'} htmlFor='lastNameField'>
                            {t('blocks-UsersList.inviteLastName')}
                        </label>
                        <TextField
                            id='lastNameField'
                            onBlur={lastName.onBlur}
                            onChange={(e) => lastName.setValue(e.target.value)}
                            type='text'
                            value={lastName.value}
                        />
                        <p className='field-error-message'>{lastName.errorMessage}</p>

                        <label className={'flex'} htmlFor='birthDateDatePicker'>
                            {t('blocks-UsersList.inviteDateOfBirth')}
                        </label>
                        <DateInput
                            id='birthDateDatePicker'
                            value={birthDate.value}
                            onChange={(date) => birthDate.setValue(date)}
                            onBlur={birthDate.onBlur}
                            hideErrorMessage={true}
                        />
                        <p className='field-error-message'>{birthDate.errorMessage}</p>

                        <label className={'flex'} htmlFor='emailField'>
                            {t('blocks-UsersList.inviteEmail')}
                        </label>
                        <TextField
                            id='emailField'
                            onBlur={email.onBlur}
                            onChange={(e) => email.setValue(e.target.value)}
                            type='email'
                            value={email.value}
                        />
                        <p className='field-error-message'>{email.errorMessage}</p>
                    </Fragment>
                )}

                <ButtonRow>
                    <Button variant={'outlined'} onClick={resetForm}>
                        {t('common.cancel')}
                    </Button>
                    <AsyncButton
                        data-cy='sendInvitation'
                        variant='contained'
                        disabled={!!companyError || !company || getWarningMessage !== null}
                        onClick={onSubmit}
                    >
                        {customerHasAcceptedRegistration
                            ? t('pages-customers.registerCustomer')
                            : t('pages-customers.sendInvite')}
                    </AsyncButton>
                </ButtonRow>
            </Modal>
        )
    )
}
