import styles from './PersonWarnings.module.scss'
import {DepositorDto} from '@fixrate/fixrate-query'
import classNames from "classnames";
import {useCommand} from "#command";

interface Props {
    depositor: DepositorDto,
    changeType: string
}

export default function BrregChanges({depositor, changeType}: Props) {

    const { acknowledgeImportedPeopleChanges } = useCommand()

    const confirm = async () => {
        try {
            await acknowledgeImportedPeopleChanges(depositor.id, changeType)
        } catch (err) {
            console.error('Failed to acknowledge changes', err)
        }
    }

    return (
        <div className={styles.warning} data-cy="brreg-changes">
            <div className={styles.sectionWrapper}>
                <i className={classNames(styles.warningIcon, "ri-error-warning-line")}/>
                <span className={styles.textBlock}>
                    <span className={styles.title}>Endringer i organisasjonsstrukturen (fra Brønnøysundsregistrene)</span>
                    <span className={styles.description}>Se over og utfør eventuelle endringer som er relevante for din organisasjon og trykk bekreft når du er ferdig.</span>
                </span>
            </div>
            <div className={styles.link} onClick={confirm} data-cy="confirm">
                Bekreft
            </div>
        </div>
    )
}
