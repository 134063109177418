import { Fragment } from 'react';
import MenuItem from './MenuItem'
import {useSelector} from '#state/useSelector'
import {isLoggedIn, messageTasksActionRequiredByUserCountSelector} from '#state/selectors'
import {useTranslation} from "react-i18next"

export default function PartnerMenu() {
    const {t} = useTranslation()
    const loggedIn = useSelector(isLoggedIn)
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)
    const partner = useSelector(state => state.partner)
    const isAccountant = partner?.partnerCategory === 'ACCOUNTANT'

    return (
        <Fragment>
            <MenuItem darkMode id='marketPlaceMenuItem' link={'/marketplace'} name={t('menu.marketplace')} remixIcon='ri-store-2-line' />
            {loggedIn && (
                <Fragment>
                    <MenuItem darkMode id='inboxMenuItem' link={'/inbox'} name={t('menu.inbox')} remixIcon='ri-inbox-line' counter={messageTasksActionRequiredByUserCount} />
                    { isAccountant ? (
                        <MenuItem darkMode id='customerMenuItem' link={'/customer'} name={t('menu.customers')} remixIcon='ri-briefcase-line'>
                            <MenuItem.SubNav darkMode to="/customer/registered">
                                {t('menu.registeredCustomers')}
                            </MenuItem.SubNav>
                            <MenuItem.SubNav darkMode to="/customer/customerList">
                                {t('menu.customerList')}
                            </MenuItem.SubNav>
                        </MenuItem>
                    ) : (
                        <MenuItem darkMode id='customerMenuItem' link={'/customer'} name={t('menu.customers')} remixIcon='ri-briefcase-line' />
                    )}
                    <MenuItem darkMode id='proposals' link={'/partner/proposals'} name={t('menu.proposals')} remixIcon='ri-mail-send-line' />
                </Fragment>
            )}
            {!loggedIn && <MenuItem id='loginMenuItem' key='login' link={'/marketplace?login'} name={t('menu.logIn')} remixIcon='ri-account-circle-line' />}
        </Fragment>
    )
}

