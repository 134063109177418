import { useSelector } from '#app/state/useSelector'
import { AccountantCustomerCompanyDto } from '@fixrate/fixrate-query'
import {useQuery} from "react-query";

type AccountantCustomersInformation = {
    customers: AccountantCustomerCompanyDto[] | undefined,
    isLoading: boolean,
    isSuccess: boolean
}
export default function useAccountantCustomers() : AccountantCustomersInformation {
    const partner = useSelector(state => state.partner)

    const {data, isLoading, isSuccess} = useQuery<AccountantCustomerCompanyDto[]>({
        queryFn: async () => {
            if (partner && partner.partnerCategory === 'ACCOUNTANT') {
                const data = await fetch(`/api/partner/accountant-customers`)
                if (!data.ok) {
                    throw new Error('Failed to fetch accountant customers')
                }
                return (await data.json())
            }
        },
        queryKey: ['accountantCustomers', partner?.id],
        staleTime: 1000 * 60 * 5
    })

    return {
        customers: data,
        isLoading,
        isSuccess
    }
}
