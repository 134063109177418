import PageHeader from '#app/components/PageHeader/PageHeader'
import config from '#app/config'
import {useConfirmationModal} from '#app/layers/ConfirmationModal/ConfirmationModal'
import {useInfoMessages} from '#app/layers/InfoMessages/InfoMessages'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import {useCommand} from '#command'
import {HelpIcon, If, LoadingSpinner} from '#components'
import OldButton from '#components/Button'
import {useTranslation} from '#components/i18n'
import Modal from '#components/Modal'
import {
    CustomerDeclarationProvider,
    useCustomerDeclaration
} from '#pages/organizations/CustomerDeclaration/CustomerDeclarationContext'
import {TextInput} from '#pages/organizations/CustomerDeclaration/widgets'
import {organisationalRoleTMap} from '#services/enumTranslationKeyMapping'
import {PersonValidator} from '#services/PersonValidator'
import useSupportPane from '#services/useSupportPane'
import {useSelector} from '#state/useSelector'
import {CustomerDeclaration as CustomerDeclarationType} from '@fixrate/fixrate-query'
import {Box, Button, Stack, useMediaQuery} from '@mui/material'
import classNames from 'classnames'
import {forwardRef, Fragment, useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useTracking} from 'react-tracking'
import styles from './CustomerDeclaration.module.scss'
import {useValidate, useValidateAll, Validate} from './Validate'
import {
    CheckboxWithLabel,
    CustomerDeclarationButton,
    List,
    Navigation,
    Question,
    Section,
    YesOrNoRadio
} from './widgets'
import { NavigationLink } from '#pages/organizations/CustomerDeclaration/widgets/Navigation'
import { createCustomerDeclarationDraft } from '#services/thunks/depositor'
import { useDispatch } from 'react-redux'

const API_BASE_URL = config().apiUrl

const About = forwardRef<HTMLDivElement>(function (_, ref) {

    const {t, i18n} = useTranslation('pages-organizations')
    const supportPane = useSupportPane()
    const session = useSelector(state => state.session)
    const {trackEvent} = useTracking({page: 'CustomerDeclaration', session})

    function showSupportArticle() {
        trackEvent({event: 'open-context-help'})
        supportPane.show("customerDeclaration")
    }

    return (
        <Section ref={ref} title="Om kundeerklæringen">
            <If condition={i18n.language !== 'nb'}>
                <p className={styles.norwegianOnlyMessage}>
                    {t('customerDeclarationNorwegianOnly')}
                </p>
            </If>
            <p>
                For å kunne opprette en konto og plassere penger i en bank, må du fylle ut en enkel
                kundeerklæring for organisasjonen. Kundeerklæringen sendes som et vedlegg til banken når du eller noen
                andre i organisasjonen bestiller et innskudd.
            </p>
            <p>
                Kundeerklæringen er nødvendig for at bankene skal oppfylle hvitvaskingsforskriften og
                øvrige myndighetskrav.
            </p>
            <Box pt={2}>
                <Button variant={'outlined'} onClick={showSupportArticle} startIcon={<i className="ri-lifebuoy-line"/>}>
                    {t('helpForCompletion')}
                </Button>
            </Box>
        </Section>
    )
})

About.displayName = 'About'


const YearlyTurnOver = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Bedriftens årlige omsetning" paths={['yearlyTurnover']}>
            <p>Oppgi omtrentlig forventet årlig omsetning. Bruk gjerne budsjetterte tall eller tall fra forrige
                årsregnskap.</p>
            <Question path={'yearlyTurnover'}>
                <TextInput
                    path={'yearlyTurnover'}
                    placeholder={'Årlig omsetning'}
                    nativePlaceholder={'Angi forventet årlig omsetning i aktuell valuta'}
                    formatter={(value) => {
                        const number = value.replace(/,|\s/gmi, '')
                        if (number === '') return value

                        if (Number.isNaN(Number(number))) {
                            return value
                        }

                        return parseFloat(number).toLocaleString('no-NO', {currency: 'NOK'})
                    }}
                    required
                />
            </Question>
        </Section>
    )
})

YearlyTurnOver.displayName = 'YearlyTurnOver'

const HasOperationsAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Driver bedriften virksomhet utenfor Norge?"
                 paths={['hasOperationsAbroad', 'operationsAbroad']}>

            <p>Har bedriften kontorer eller driver bedriften virksomhet i andre land?</p>
            <Question path={'hasOperationsAbroad'}>
                <YesOrNoRadio
                    path={'hasOperationsAbroad'}
                    data-cy="hasOperationsAbroad"
                />
            </Question>
            <Question
                title={'Land utenom Norge hvor bedriften har virksomhet'}
                path={'operationsAbroad'}
                hide={!customerDeclaration.hasOperationsAbroad}
                className={styles.fullWidth}
            >
                <p>Oppgi firmanavn dersom dette avviker fra firmanavnet i Norge.</p>
                <List
                    path={'operationsAbroad'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flere land
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                path={`operationsAbroad.${index}.country`}
                                placeholder={'Land'}
                                required
                            />
                            <TextInput
                                path={`operationsAbroad.${index}.name`}
                                placeholder={'Firmanavn'}
                            />
                        </Stack>
                    )}
                </List>
            </Question>
        </Section>
    )
})

HasOperationsAbroad.displayName = 'HasOperationsAbroad'

const PubliclyListed = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Er bedriften børsnotert?" paths={['publiclyListed']}>
            <p>Er bedriften børsnotert på Oslo børs eller en annen børs?</p>
            <Question path={'publiclyListed'}>
                <YesOrNoRadio
                    path={'publiclyListed'}
                    data-cy="publiclyListed"
                />
            </Question>
        </Section>
    )
})

PubliclyListed.displayName = 'PubliclyListed'

const HasParentCompany = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Har bedriften et morselskap?" hide={customerDeclaration.publiclyListed}
                 paths={['hasParentCompany', 'ultimateParent']}>
            <p>Et morselskap er et selskap, som ikke er et investeringsselskap, som har aksjemajoritet eller på annen
                måte bestemmende innflytelse over bedriften.
                Hvis bedriften har et morselskap regnes det som å være en del av et konsern.</p>

            <Question path={'hasParentCompany'}>
                <YesOrNoRadio
                    path={'hasParentCompany'}
                    data-cy="hasParentCompany"
                />
            </Question>

            <Question
                title="Toppselskapet i konsernet"
                path={'ultimateParent'}
                hide={customerDeclaration.hasParentCompany !== true}
                className={styles.fullWidth}
            >
                <TextInput
                    path={'ultimateParent.name'}
                    placeholder={'Navn på toppselskap'}
                    required
                />
                <TextInput
                    path={'ultimateParent.nationalIdentity'}
                    placeholder={'Foretaksnummer/utenlandsk identifikasjonsnummer'}
                    required
                />
                <TextInput
                    path={'ultimateParent.country'}
                    placeholder={'Land'}
                    required
                />
            </Question>

            <Question
                title="Er toppselskapet børsnotert?"
                path={'ultimateParent.publiclyListed'}
                hide={customerDeclaration.hasParentCompany !== true}
            >
                <YesOrNoRadio path={'ultimateParent.publiclyListed'}/>
            </Question>

            <Question
                title="Beskriv hvor bedriften er plassert i selskapsstrukturen"
                path={'relationToUltimateParent'}
                hide={customerDeclaration.hasParentCompany !== true}
                className={styles.fullWidth}
            >
                <p>Beskriv kort relasjonen mellom bedriften og toppselskapet i konsernet.</p>
                <TextInput
                    path={'relationToUltimateParent'}
                    placeholder="Selskapsstruktur"
                    nativePlaceholder={'Beskriv hvor bedriften er plassert i selskapsstrukturen'}
                    required
                    type="textarea"
                />
            </Question>

        </Section>
    )
})

HasParentCompany.displayName = 'HasParentCompany'

const MainlyPassiveIncome = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Har bedriften hovedsakelig passive inntekter/formue?" paths={['mainlyPassiveIncome']}>
            <p>Passive inntekter er:</p>
            <ul className={styles.infoList}>
                <li>Renteinntekter.</li>
                <li>Dividender/utbytte og lignende utbetalinger.</li>
                <li>Eierinntekter og royalties, unntatt leieinntekter og royalties fra aktiv virksomhet helt eller
                    delvis utøvet av virksomhetens ansatte.
                </li>
                <li>Livrenter.</li>
                <li>Nettogevinster ved salg eller bytte av finansielle instrumenter og andre finansielle produkter som
                    gir opphav til passive inntekter tilsvarende
                    inntekter nevnt over.
                </li>
                <li>Nettogevinster fra transaksjoner i finansielle instrumenter og andre finansielle produkter (herunder
                    futures, forwards, opsjoner og lignende
                    transaksjoner).
                </li>
                <li>Netto valutagevinster.</li>
                <li>Nettoinntekter fra finansielle instrumenter som gir betaling fra en part til en annen ved gitte
                    intervaller beregnet på grunnlag av en nærmere
                    angitt indeks på en pålydende verdi i bytte for spesifiserte vederlag eller et løfte om å betale
                    tilsvarende beløp (swap).
                </li>
                <li>Beløp mottatt fra forsikringsavtaler med gjenkjøpsverdi</li>
            </ul>
            <Question path={'mainlyPassiveIncome'}>
                <YesOrNoRadio
                    path={'mainlyPassiveIncome'}
                    data-cy="mainlyPassiveIncome"
                />
            </Question>
        </Section>
    )
})

MainlyPassiveIncome.displayName = 'MainlyPassiveIncome'

const AccountingBalance = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Regnskapsmessig balanse" paths={['accountingBalanceOver20millEuro']}>
            <p>Har bedriften en regnskapsmessig balanse som tilsvarer minst 20 millioner euro?</p>
            <Question path={'accountingBalanceOver20millEuro'}>
                <YesOrNoRadio
                    path={'accountingBalanceOver20millEuro'}
                    data-cy="accountingBalanceOver20millEuro"
                />
            </Question>
        </Section>
    )
})

AccountingBalance.displayName = 'AccountingBalance'

const EquityCapital = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Egenkapital" paths={['equityCapitalOver2millEuro']}>
            <p>Har bedriften en egenkapital som tilsvarer minst 2 millioner euro?</p>
            <Question path={'equityCapitalOver2millEuro'}>
                <YesOrNoRadio
                    path={'equityCapitalOver2millEuro'}
                    data-cy="equityCapitalOver2millEuro"
                />
            </Question>
        </Section>
    )
})

EquityCapital.displayName = 'EquityCapital'

const CryptoCurrencies = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Kryptovaluta" paths={['cryptoCurrencies']}>
            <p>Handler bedriften med noen form for kryptovaluta?</p>
            <Question path={'cryptoCurrencies'}>
                <YesOrNoRadio
                    path={'cryptoCurrencies'}
                    data-cy="cryptoCurrencies"
                />
            </Question>

            <Question
                title="Beskriv handelen med kryptovaluta"
                path={'cryptoCurrenciesDescription'}
                hide={!customerDeclaration.cryptoCurrencies}
            >
                <p>Beskriv hensikten og formålet med handelen, inkludert hvorfor, hvor mye det handles for,
                    hvilken kryptovaluta, hvor handelen foregår, hvilken rolle bedriften har i handelen,
                    dvs. formidler, utvinner, handler.</p>
                <TextInput
                    path={'cryptoCurrenciesDescription'}
                    type="textarea"
                    placeholder={'Beskriv handelen'}
                    required
                />
            </Question>
        </Section>
    )
})

CryptoCurrencies.displayName = 'CryptoCurrencies'

const FinancialInstitution = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Er bedriften en finansinstitusjon?" paths={['financialInstitution', 'giin']}>
            <p>Er bedriften en finansiell institusjon som tilbyr tjenester innen bank, finans, forsikring, verdipapir
                eller tilsvarende?</p>
            <Question path={'financialInstitution'}>
                <YesOrNoRadio
                    path={'financialInstitution'}
                    data-cy="financialInstitution"
                />
            </Question>

            <Question
                title="Global Intermediary Identification Number (GIIN)"
                path={'giin'}
                hide={!customerDeclaration.financialInstitution}
            >
                <p>Oppgi årsak dersom nummeret mangler</p>
                <TextInput
                    path={'giin'}
                    placeholder={'GIIN'}
                    type={'textarea'}
                    required
                />
            </Question>
        </Section>
    )
})

FinancialInstitution.displayName = 'FinancialInstitution'


const EmailAddress = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Bedriftens e-postadresse" paths={['emailAddress']}>
            <p>E-postadresse som rapporteres inn fordi den skal brukes mot Bankenes sikringsfond.</p>
            <Question path={'emailAddress'}>
                <TextInput
                    path={'emailAddress'}
                    placeholder={'E-postadresse'}
                    nativePlaceholder={'kontakt@bedrift.no'}
                    required
                />
            </Question>
        </Section>
    )
})

EmailAddress.displayName = 'EmailAddress'

const ShareClasses = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Aksjeklasser" paths={['financialInstitution', 'giin']}>
            <p>Finnes det flere aksjeklasser som påvirker hvem som i realiteten kontrollerer bedriften?</p>
            <Question path={'shareClasses'}>
                <YesOrNoRadio
                    path={'shareClasses'}
                    data-cy="shareClasses"
                />
            </Question>

            <Question
                title="Beskriv de ulike aksjeklassene"
                path={'shareClassesDescription'}
                hide={!customerDeclaration.shareClasses}
            >
                <TextInput
                    path={'shareClassesDescription'}
                    type="textarea"
                    placeholder={'Beskrivelse av aksjeklassene'}
                    required
                />
            </Question>
        </Section>
    )
})

ShareClasses.displayName = 'ShareClasses'

const ShareHolderAgreement = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Aksjonæravtale som påvirker kontroll over bedriften"
                 paths={['shareHolderAgreement', 'shareHolderAgreementDescription']}>
            <p>Foreligger det vedtekter, aksjonæravtaler eller annen avtale som har bestemmelser om hvem som i
                realiteten (direkte eller indirekte) kontrollerer bedriften?</p>
            <Question path={'shareHolderAgreement'}>
                <YesOrNoRadio
                    path={'shareHolderAgreement'}
                    data-cy="shareHolderAgreement"
                />
            </Question>

            <Question
                title="Beskriv avtalene og hvilke personer som har reell kontroll over bedriften"
                path={'shareHolderAgreementDescription'}
                hide={!customerDeclaration.shareHolderAgreement}
            >
                <TextInput
                    path={'shareHolderAgreementDescription'}
                    type="textarea"
                    placeholder={'Beskrivelse av aksjonæravtaler'}
                    required
                />
            </Question>
        </Section>
    )
})

ShareHolderAgreement.displayName = 'ShareHolderAgreement'

const PaymentProcessor = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title='Skal bedriften drive med betalingsformidling?' paths={['paymentProcessor']}>
            <p>Skal virksomheten drive med betalingsformidlingsvirksomhet eller lignende som er underlagt registreringsplikt og kontroll fra Finanstilsynet?</p>
            <Question path={'paymentProcessor'}>
                <YesOrNoRadio path={'paymentProcessor'} data-cy='paymentProcessor' />
            </Question>
            <Question title='Oppgi konsesjonslisens' path={'paymentProcessorDescription'} hide={!customerDeclaration.paymentProcessor}>
                <TextInput path={'paymentProcessorDescription'} placeholder={'Oppgi konsesjonslisens'} required />
            </Question>
        </Section>
    )
})

PaymentProcessor.displayName = 'PaymentProcessor'

const FundOrigins = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()

    const {setValid, setTouched, isTouched} = useValidate('fundOrigins')

    const atLeastOneOptionPicked =
        !!customerDeclaration.fundOrigins.businessOperations
        || !!customerDeclaration.fundOrigins.returnOnInvestments
        || !!customerDeclaration.fundOrigins.loan
        || !!customerDeclaration.fundOrigins.rentalIncome
        || !!customerDeclaration.fundOrigins.fundManagement
        || !!customerDeclaration.fundOrigins.paidUpEquity
        || !!customerDeclaration.fundOrigins.publicSubsidies
        || !!customerDeclaration.fundOrigins.saleOfAssets
        || !!customerDeclaration.fundOrigins.other

    const errorMsg = !atLeastOneOptionPicked && 'Velg minst ett alternativ'

    useEffect(() => {
        setValid(atLeastOneOptionPicked)
    }, [setValid, atLeastOneOptionPicked])

    function onChange() {
        setTouched(true)
    }

    return (
        <Section ref={ref} title="Midlenes opprinnelse" paths={['fundOrigins']}>
            <p>Oppgi hvor midlene som skal settes inn på plasseringskonto via Fixrate kommer fra.</p>
            <p>Du kan krysse av for ett eller flere alternativer.</p>
            <p>Forståelsen av midlenes opprinnelse er avgjørende for banker ved etablering av nye kundeforhold,
                spesielt med bedrifter. Dette skyldes nødvendigheten av å forebygge hvitvasking av penger, overholde
                strenge regelverk, håndtere risiko effektivt, beskytte bankens omdømme, og sikre internasjonalt
                samarbeid.
                Ved å identifisere opprinnelsen til midlene kan banker sikre at de ikke støtter ulovlige aktiviteter og
                oppfyller både nasjonale og internasjonale krav. Dette bidrar til lovlig og etisk drift, samtidig som
                det beskytter mot økonomiske og omdømmemessige risikoer.</p>
            <p>Gi så god og detaljert informasjon som mulig for de kategoriene som er aktuelle for din bedrift.
                God og nok informasjon bidrar til raskere saksbehandling hos
                banken. <HelpIcon supportCommonName='originOfFunds' inline/></p>

            <Question path={'fundOrigins'}>
                <CheckboxWithLabel
                    id="businessOperationsCheckbox"
                    label="Virksomhetens drift"
                    path={'fundOrigins.businessOperations'}
                    data-cy="fundOrigins-businessOperations"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.businessOperations === true && (
                    <TextInput
                        path={'fundOrigins.businessOperationsSpecify'}
                        placeholder="Beskriv virksomhetens drift"
                        required
                        type="textarea"
                        data-cy="fundOrigins-businessOperations-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Avkastning fra investeringer"
                    path={'fundOrigins.returnOnInvestments'}
                    data-cy="fundOrigins-returnOnInvestments"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.returnOnInvestments === true && (
                    <TextInput
                        path={'fundOrigins.returnOnInvestmentsSpecify'}
                        placeholder="Beskriv investeringene"
                        required
                        type="textarea"
                        data-cy="fundOrigins-returnOnInvestments-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Lån"
                    path={'fundOrigins.loan'}
                    data-cy="fundOrigins-loan"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.loan === true && (
                    <TextInput
                        path={'fundOrigins.loanSpecify'}
                        placeholder="Beskriv låneforhold"
                        required
                        type="textarea"
                        data-cy="fundOrigins-loan-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Leieinntekter"
                    path={'fundOrigins.rentalIncome'}
                    data-cy="fundOrigins-rentalIncome"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.rentalIncome === true && (
                    <TextInput
                        path={'fundOrigins.rentalIncomeSpecify'}
                        placeholder="Beskriv leieforhold"
                        required
                        type="textarea"
                        data-cy="fundOrigins-rentalIncome-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Fondsforvaltning på vegne av andre"
                    path={'fundOrigins.fundManagement'}
                    data-cy="fundOrigins-fundManagement"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.fundManagement === true && (
                    <TextInput
                        path={'fundOrigins.fundManagementSpecify'}
                        placeholder="Beskriv fondsforvaltningen"
                        required
                        type="textarea"
                        data-cy="fundOrigins-fundManagement-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Innskutt egenkapital"
                    path={'fundOrigins.paidUpEquity'}
                    data-cy="fundOrigins-paidUpEquity"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.paidUpEquity === true && (
                    <TextInput
                        path={'fundOrigins.paidUpEquitySpecify'}
                        placeholder="Beskriv egenkapital"
                        required
                        type="textarea"
                    />
                )}
                <CheckboxWithLabel
                    label="Offentlige tilskudd"
                    path={'fundOrigins.publicSubsidies'}
                    data-cy="fundOrigins-publicSubsidies"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.publicSubsidies === true && (
                    <TextInput
                        path={'fundOrigins.publicSubsidiesSpecify'}
                        placeholder="Beskriv tilskudd"
                        required
                        type="textarea"
                        data-cy="fundOrigins-publicSubsidies-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Salg av eiendeler"
                    path={'fundOrigins.saleOfAssets'}
                    data-cy="fundOrigins-saleOfAssets"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.saleOfAssets === true && (
                    <TextInput
                        path={'fundOrigins.saleOfAssetsSpecify'}
                        placeholder="Beskriv eiendeler"
                        required
                        type="textarea"
                        data-cy="fundOrigins-saleOfAssets-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Annet"
                    path={'fundOrigins.other'}
                    data-cy="fundOrigins-other"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.other === true && (
                    <TextInput
                        path={'fundOrigins.specify'}
                        placeholder="Beskriv midlenes opprinnelse"
                        required
                        type="textarea"
                        data-cy="fundOrigins-other-textField"
                    />
                )}
                <p className="field-error-message">{isTouched() && errorMsg}</p>
            </Question>
        </Section>
    )
})

FundOrigins.displayName = 'FundOrigins'

const HasPaymentRecievedAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Vil innskudd overføres til utlandet når de tas ut?"
                 paths={['hasPaymentsMadeAbroad', 'paymentsMadeAbroad']}>
            <p>Vil innskudd gjort gjennom Fixrate overføres direkte til utlandet når de tas ut? Dette er bare aktuelt
                dersom du registrerer en utenlands oppgjørskonto i Fixrate.</p>
            <Question path="hasPaymentsMadeAbroad">
                <YesOrNoRadio
                    path="hasPaymentsMadeAbroad"
                    data-cy="hasPaymentsMadeAbroad"
                />
            </Question>
            <Question title="Land hvor innskudd vil overføres til når de tas ut" path="paymentsMadeAbroad"
                      hide={customerDeclaration.hasPaymentsMadeAbroad !== true}>
                <p>Oppgi til hvilke land beløpet vil overføres og årsaken til at beløpet overføres til utlandet.</p>
                <List
                    path={'paymentsMadeAbroad'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flere land
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                placeholder={'Land'}
                                path={`paymentsMadeAbroad.${index}.country`}
                                required
                            />
                            <TextInput
                                placeholder={'Årsak'}
                                path={`paymentsMadeAbroad.${index}.reason`}
                                required
                            />
                        </Stack>
                    )}
                </List>
            </Question>
        </Section>
    )
})

HasPaymentRecievedAbroad.displayName = 'HasPaymentRecievedAbroad'

const HasPaymentsMadeAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Vil innskudd innbetales fra utlandet?"
                 paths={['hasPaymentsReceivedAbroad', 'paymentsReceivedAbroad']}>
            <p>Vil innskudd gjennom Fixrate innbetales direkte fra en konto i utlandet?</p>
            <Question path="hasPaymentsReceivedAbroad">
                <YesOrNoRadio
                    path="hasPaymentsReceivedAbroad"
                    data-cy="hasPaymentsReceivedAbroad"
                />
            </Question>

            <Question title="Land hvor innskudd vil innbetales fra" path="paymentsReceivedAbroad"
                      hide={customerDeclaration.hasPaymentsReceivedAbroad !== true}>
                <p>Oppgi fra hvilke land beløpet vil innbetales og årsaken til at beløpet innbetales fra
                    utlandet.</p>
                <List
                    path={'paymentsReceivedAbroad'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flere land
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                placeholder={'Land'}
                                path={`paymentsReceivedAbroad.${index}.country`}
                                required
                            />
                            <TextInput
                                placeholder={'Årsak'}
                                path={`paymentsReceivedAbroad.${index}.reason`}
                                required
                            />
                        </Stack>
                    )}
                </List>
            </Question>

        </Section>
    )
})

HasPaymentsMadeAbroad.displayName = 'HasPaymentsMadeAbroad'

const AmericanCompany = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
            <Section ref={ref} path='americanCompany' title='Er bedriften en amerikansk juridisk person eller sammenslutning?' paths={['americanCompany', 'americanCompanyTin']}>
                <Question path='americanCompany'>
                    <YesOrNoRadio path='americanCompany' data-cy='americanCompany' />
                </Question>

                <Question path='americanCompanyTin' title='Amerikansk identifikasjonsnummer (TIN)' hide={customerDeclaration.americanCompany !== true}>
                    <TextInput placeholder={'Amerikansk TIN'} path={'americanCompanyTin'} unit={<span>Oppgi årsak dersom nummeret mangler</span>} required />
                </Question>
            </Section>
    )
})

AmericanCompany.displayName = 'AmericanCompany'

const CompanyTaxpayerAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Er bedriften skattemessig hjemmehørende i andre land enn Norge?"
                 paths={['companyTaxpayerAbroad', 'companyTin']}>
            <Question path="companyTaxpayerAbroad">
                <YesOrNoRadio
                    path="companyTaxpayerAbroad"
                    data-cy="companyTaxpayerAbroad"
                />
            </Question>
            <Question title="Land hvor bedriften er skattemessig hjemmehørende" path={'companyTin'}
                      hide={customerDeclaration.companyTaxpayerAbroad !== true}>
                <p>Oppgi bedriftens utenlandske organisasjonsnummer / tax identification number</p>
                <List
                    path={'companyTin'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flere land
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                path={`companyTin.${index}.country`}
                                placeholder={'Land'}
                                required
                            />
                            <TextInput
                                path={`companyTin.${index}.tin`}
                                placeholder={'Utenlandsk ID-nummer'}
                            />
                        </Stack>
                    )}
                </List>
            </Question>
        </Section>
    )
})

CompanyTaxpayerAbroad.displayName = 'CompanyTaxpayerAbroad'

const AgreeToUpdate = forwardRef<HTMLDivElement>(function (_, ref) {
    const path = 'agreeToUpdate'
    const agreeToUpdate = useCustomerDeclaration<boolean>(path)

    const {setValid, isTouched} = useValidate(path)

    useEffect(() => {
        setValid(agreeToUpdate === true)
    }, [setValid, agreeToUpdate])

    return (
        <Section ref={ref} title="Oppdateringsplikt">
            <p>Kundeerklæringen må til enhver tid inneholde oppdatert informasjon om bedriften</p>
            <Question path="agreeToUpdate">
                <CheckboxWithLabel
                    label="Vi aksepterer at bedriften er pliktig til å oppdatere og signere kundeerklæringen på nytt dersom det skjer endringer."
                    path="agreeToUpdate"
                    data-cy="agreeToUpdate"
                />
                <p className="field-error-message">{agreeToUpdate !== true && isTouched() && 'Du må akseptere oppdateringsplikten'}</p>
            </Question>
        </Section>
    )
})

AgreeToUpdate.displayName = 'AgreeToUpdate'

const People = forwardRef<HTMLDivElement>(function (_, ref) {
    const {t} = useTranslation('pages-organizations')
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()

    if (!depositor) {
        return null
    }

    const allPersonsAreReady = PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)

    const customerDeclarationPeople = depositor.people.filter(person => PersonValidator.isCustomerDeclarationPerson(person))

    const goToProfile = (link) => {
        navigate(link)
    }

    return (
        <Fragment>
            <Section ref={ref} title={'Reelle rettighetshavere og nøkkelpersoner'}>
                <p>
                    Kundeerklæringen skal inneholde en oversikt over reelle rettighetshavere og nøkkelpersoner i
                    bedriften.
                </p>
                <p>
                    Følgende informasjon om styremedlemmer, daglig leder / kontaktperson og reelle rettighetshavere
                    er registrert i bedriftens persongalleri.
                </p>
                {customerDeclarationPeople.length > 0 && (
                    <table>
                        <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Roller</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {customerDeclarationPeople.map(person => (
                            <tr key={person.associatedUserId}
                                onClick={() => goToProfile(`/organizations/${depositor.id}/users/profile/${person.personId}`)}>
                                <td>{person.firstName} {person.lastName}</td>
                                <td>
                                    {person.organisationalRoles.map(role => <span
                                        key={role}>{t(organisationalRoleTMap[role])}</span>)}
                                </td>
                                <td>
                                    {
                                        PersonValidator.isCompleteForCustomerDeclaration(depositor, person)
                                            ? <span><i className="ri-check-line green"/></span>
                                            : <span><i
                                                className="ri-error-warning-line red"/><span>Mangler informasjon</span></span>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                <p>
                    Hvis informasjonen beskrevet over ikke stemmer kan du endre dette i <Link
                    to={`/organizations/${depositor.id}/users/board`}>organisasjonsoversikten</Link>
                </p>
                {!allPersonsAreReady &&
                    <p className={'field-error-message'}>Alle opplysninger om styre, ledelse og eiere må fylles ut før
                        kundeerklæringen kan opprettes.</p>}
            </Section>
        </Fragment>
    )
})

People.displayName = 'People'

function CustomerDeclaration() {

    const {generateCustomerDeclaration} = useCommand()
    const confirmationModal = useConfirmationModal()
    const {showInfoMessage} = useInfoMessages()
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()
    const {areAllValid, touchAll} = useValidateAll()
    const isMobile = useMediaQuery('(max-width: 800px)')

    const [validating, setValidating] = useState(false)
    const [validationOk, setValidationOk] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [hasDraft, setHasDraft] = useState(depositor.customerDeclarationDraftState !== 'NO_DRAFT')
    const [progressModalOpen, setProgressModalOpen] = useState(false)
    const scrollId = null
    const dispatch = useDispatch()

    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    useEffect(() => {
        if (!hasDraft && depositor.customerDeclarationDraftState === 'NO_DRAFT') {
            dispatch(createCustomerDeclarationDraft(depositor.id))
            setHasDraft(true)
        }
    }, [hasDraft, depositor, dispatch])

    async function onSubmitCustomerDeclaration() {
        setProgressModalOpen(true)
        setValidating(true)
        await wait(3_000)
        const valid = areAllValid() && PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)
        setValidating(false)
        setValidationOk(valid)
        await wait(2_000)
        if (!valid) {
            touchAll()
            setProgressModalOpen(false)
            return
        }
        setSubmitting(true)
        const {waitForCommand} = await generateCustomerDeclaration(depositor.id)
        const abortInfoMessage1 = showInfoMessage({
            type: 'info',
            message: 'Vi produserer dokumentet ...',
            delay: 10_000
        })
        const abortInfoMessage2 = showInfoMessage({
            type: 'info',
            message: 'Vi produserer fortsatt dokumentet. Beklager ventetiden ...',
            delay: 30_000
        })
        const success = await waitForCommand(120_000)
        abortInfoMessage1()
        abortInfoMessage2()
        if (success) {
            navigate(`${API_BASE_URL}/organizations/${depositor.id}/customerdecl`)
        } else {
            confirmationModal('Kundeerklæring', 'Det tok svært lang tid å produsere dokumentet. Ta gjerne kontakt med support hvis det ikke dukker opp.', 'Fortsett')
            navigate(`${API_BASE_URL}/organizations/${depositor.id}/customerdecl`)
        }
    }

    // Ref to the scrollable container
    const scrollableContainer = useRef()

    // Refs to each section in the scrollable container
    const about = useRef()
    const yearlyTurnover = useRef()
    const accountingBalanceOver20millEuro = useRef()
    const equityCapitalOver2millEuro = useRef()
    const hasOperationsAbroad = useRef()
    const publiclyListed = useRef()
    const hasParentCompany = useRef()
    const mainlyPassiveIncome = useRef()
    const cryptoCurrencies = useRef()
    const paymentProcessor = useRef()
    const financialInstitution = useRef()
    const emailAddress = useRef()
    const shareClasses = useRef()
    const shareHolderAgreement = useRef()
    const americanCompany = useRef()
    const agreeToUpdate = useRef()
    const companyTaxpayerAbroad = useRef()
    const hasPaymentRecievedAbroad = useRef()
    const hasPaymentsMadeAbroad = useRef()
    const fundOrigins = useRef()
    const people = useRef()
    const createDocument = useRef()

    const validLink = (path) => {
        return areAllValid({paths: [path]}) === true ? true : null
    }

    const errorLink = (paths = []) => {
        return areAllValid({paths}) === false ? true : null
    }

    if (!depositor) {
        return null
    } else {

        const allPersonsAreReady = PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)

        return (
            <CustomerDeclarationProvider value={depositor.customerDeclaration}>
                {progressModalOpen && (
                    <Modal header={'Lager dokument'} width={'50rem'} onCancel={() => setProgressModalOpen(false)}>
                        <div className={styles.modalContent}>
                            {!submitting && (
                                <div className={styles.progressStep}>
                                    {validating && <i className={classNames('ri-search-eye-line', styles.search)} />}
                                    {!validating && validationOk && <i className={classNames('ri-check-line', styles.validationResult)} />}
                                    {!validating && !validationOk && <i className={classNames('ri-close-line', styles.validationResult)} />}

                                    <p>Validerer skjema</p>
                                    <div className={styles.progressBar} />
                                </div>
                            )}
                            {submitting && (
                                <div className={styles.progressStep}>
                                    <i className={classNames('ri-settings-5-line', styles.cog)} />
                                    <LoadingSpinner text={'Produserer PDF...'} />
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
                <PageHeader title={isMobile ? 'Kundeerklæring' : `Kundeerklæring for ${depositor.name}`} useBackButton />
                <div className={styles.content}>
                    <div className={styles.links}>
                        <h3 className={styles.linksHeader}>Om bedriften</h3>
                        <Navigation scrollableContainer={scrollableContainer}>
                            <NavigationLink success={validLink('yearlyTurnover')} error={errorLink(['yearlyTurnover'])} to={yearlyTurnover} active={scrollId === 'yearlyTurnover'}>
                                Bedriftens årlige omsetning
                            </NavigationLink>

                            <NavigationLink
                                success={validLink('accountingBalanceOver20millEuro')}
                                error={errorLink(['accountingBalanceOver20millEuro'])}
                                to={accountingBalanceOver20millEuro}
                                active={scrollId === 'accountingBalanceOver20millEuro'}
                            >
                                Regnskapsmessig balanse
                            </NavigationLink>

                            <NavigationLink
                                success={validLink('equityCapitalOver2millEuro')}
                                error={errorLink(['equityCapitalOver2millEuro'])}
                                to={equityCapitalOver2millEuro}
                                active={scrollId === 'equityCapitalOver2millEuro'}
                            >
                                Egenkapital
                            </NavigationLink>

                            <NavigationLink
                                to={hasOperationsAbroad}
                                error={errorLink(['hasOperationsAbroad', 'operationsAbroad'])}
                                success={validLink('hasOperationsAbroad')}
                                active={scrollId === 'hasOperationsAbroad'}
                            >
                                Driver bedriften virksomhet utenfor Norge?
                            </NavigationLink>

                            <NavigationLink
                                to={companyTaxpayerAbroad}
                                success={validLink('companyTaxpayerAbroad')}
                                error={errorLink(['companyTaxpayerAbroad', 'companyTin'])}
                                active={scrollId === 'companyTaxpayerAbroad'}
                            >
                                Er bedriften skattemessig hjemmehørende i et annet land enn Norge?
                            </NavigationLink>

                            <NavigationLink
                                to={americanCompany}
                                success={validLink('americanCompany')}
                                error={errorLink(['americanCompany', 'americanCompanyTin'])}
                                active={scrollId === 'americanCompany'}
                            >
                                Er bedriften en amerikansk juridisk person eller sammenslutning?
                            </NavigationLink>

                            <NavigationLink to={publiclyListed} success={validLink('publiclyListed')} error={errorLink(['publiclyListed'])} active={scrollId === 'publiclyListed'}>
                                Er bedriften børsnotert?
                            </NavigationLink>

                            {depositor.customerDeclaration.publiclyListed ? null : (
                                <NavigationLink
                                    to={hasParentCompany}
                                    error={errorLink(['hasParentCompany', 'ultimateParent', 'relationToUltimateParent'])}
                                    success={validLink('hasParentCompany')}
                                    active={scrollId === 'hasParentCompany'}
                                >
                                    Har bedriften et morselskap?
                                </NavigationLink>
                            )}

                            <NavigationLink to={mainlyPassiveIncome} success={validLink('mainlyPassiveIncome')} error={errorLink(['mainlyPassiveIncome'])} active={scrollId === 'mainlyPassiveIncome'}>
                                Har bedriften hovedsakelige passive inntekter/formue?
                            </NavigationLink>

                            <NavigationLink
                                to={cryptoCurrencies}
                                success={validLink('cryptoCurrencies')}
                                error={errorLink(['cryptoCurrencies', 'cryptoCurrenciesDescription'])}
                                active={scrollId === 'cryptoCurrencies'}
                            >
                                Kryptovaluta
                            </NavigationLink>

                            <NavigationLink
                                to={paymentProcessor}
                                success={validLink('paymentProcessor')}
                                error={errorLink(['paymentProcessor', 'paymentProcessorDescription'])}
                                active={scrollId === 'paymentProcessor'}
                            >
                                Skal bedriften drive med betalingsformidling?
                            </NavigationLink>

                            <NavigationLink
                                to={financialInstitution}
                                success={validLink('financialInstitution')}
                                error={errorLink(['financialInstitution', 'giin'])}
                                active={scrollId === 'financialInstitution'}
                            >
                                Er bedriften en finansinstitusjon?
                            </NavigationLink>

                            <NavigationLink
                                to={emailAddress}
                                success={validLink('emailAddress')}
                                error={errorLink(['emailAddress'])}
                                active={scrollId === 'emailAddress'}
                            >
                                Bedriftens e-postadresse
                            </NavigationLink>

                            <h3 className={styles.linksHeader}>Om midlene</h3>

                            <NavigationLink to={fundOrigins} success={validLink('fundOrigins')} error={errorLink(['fundOrigins'])} active={scrollId === 'fundOrigins'}>
                                Midlenes opprinnelse
                            </NavigationLink>

                            <NavigationLink
                                to={hasPaymentRecievedAbroad}
                                success={validLink('hasPaymentsMadeAbroad')}
                                error={errorLink(['hasPaymentsMadeAbroad', 'paymentsMadeAbroad'])}
                                active={scrollId === 'hasPaymentsMadeAbroad'}
                            >
                                Vil innskudd gjennom Fixrate overføres til utlandet når de tas ut?
                            </NavigationLink>

                            <NavigationLink
                                to={hasPaymentsMadeAbroad}
                                success={validLink('hasPaymentsReceivedAbroad')}
                                error={errorLink(['hasPaymentsReceivedAbroad', 'paymentsReceivedAbroad'])}
                                active={scrollId === 'hasPaymentsReceivedAbroad'}
                            >
                                Vil innskudd gjennom Fixrate innbetales fra utlandet?
                            </NavigationLink>

                            <h3 className={styles.linksHeader}>Eierskap og kontroll</h3>

                            <NavigationLink to={people} success={allPersonsAreReady} error={!allPersonsAreReady} active={scrollId === 'people'}>
                                Reelle rettighetshavere og nøkkelpersoner
                            </NavigationLink>

                            <NavigationLink to={shareClasses} success={validLink('shareClasses')} error={errorLink(['shareClasses', 'shareClassesDescription'])} active={scrollId === 'shareClasses'}>
                                Aksjeklasser
                            </NavigationLink>

                            <NavigationLink
                                to={shareHolderAgreement}
                                success={validLink('shareHolderAgreement')}
                                error={errorLink(['shareHolderAgreement', 'shareHolderAgreementDescription'])}
                                active={scrollId === 'shareHolderAgreement'}
                            >
                                Aksjonæravtaler
                            </NavigationLink>

                            <div ref={createDocument} className={styles.navigationButtonRow}>
                                {areAllValid() || <p className='field-error-message'>Alle obligatoriske felt må fylles ut</p>}
                                <OldButton id='submitCustomerDeclarationButton' onClick={onSubmitCustomerDeclaration}>
                                    Lag dokumentet
                                </OldButton>
                            </div>
                        </Navigation>
                    </div>
                    <div ref={scrollableContainer} className={styles.body}>
                        <About ref={about} />
                        <YearlyTurnOver ref={yearlyTurnover} />
                        <AccountingBalance ref={accountingBalanceOver20millEuro} />
                        <EquityCapital ref={equityCapitalOver2millEuro} />
                        <HasOperationsAbroad ref={hasOperationsAbroad} />
                        <CompanyTaxpayerAbroad ref={companyTaxpayerAbroad} />
                        <AmericanCompany ref={americanCompany} />
                        <PubliclyListed ref={publiclyListed} />
                        <HasParentCompany ref={hasParentCompany} />
                        <MainlyPassiveIncome ref={mainlyPassiveIncome} />
                        <CryptoCurrencies ref={cryptoCurrencies} />
                        <PaymentProcessor ref={paymentProcessor} />
                        <FinancialInstitution ref={financialInstitution} />
                        <EmailAddress ref={emailAddress} />
                        <FundOrigins ref={fundOrigins} />
                        <HasPaymentRecievedAbroad ref={hasPaymentRecievedAbroad} />
                        <HasPaymentsMadeAbroad ref={hasPaymentsMadeAbroad} />
                        <People ref={people} />
                        <ShareClasses ref={shareClasses} />
                        <ShareHolderAgreement ref={shareHolderAgreement} />
                        <AgreeToUpdate ref={agreeToUpdate} />

                        <section ref={createDocument} className={styles.buttonRow}>
                            {areAllValid() || <p className='field-error-message'>Alle obligatoriske felt må fylles ut</p>}
                            {allPersonsAreReady || <p className='field-error-message'>Persongalleriet må fylles ut</p>}
                            <OldButton id='submitCustomerDeclarationButton' onClick={onSubmitCustomerDeclaration}>
                                Lag dokumentet
                            </OldButton>
                        </section>
                    </div>
                </div>
            </CustomerDeclarationProvider>
        )
    }
}

export function CustomerDeclarationNO() {
    return (
        <Validate>
            <CustomerDeclaration/>
        </Validate>
    )
}

