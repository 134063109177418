import FxPaper from '#app/components/Paper/FxPaper'
import config from '#app/config'
import { InterestRateChangeExtendedDto } from '#app/state/selectors'
import { useCommand } from '#command'
import { ActionBlock, DateOutput, HelpIcon } from '#components'
import Button from '#components/Button'
import { useTranslation } from '#components/i18n'
import { useSelector } from '#state/useSelector'
import { useNavigate } from 'react-router-dom'
import DocumentIcon from '../../common/icons/DocumentIcon'
import styles from './InterestRateChangeBankDetails.module.css'

const API_BASE_URL = config().apiUrl


export default function NotSentToDepositor({interestRateChange}: { interestRateChange: InterestRateChangeExtendedDto }) {
    const {t} = useTranslation('pages-interestratechange-bank')
    const navigate = useNavigate()

    const {sendInterestRateChangeToDepositor, cancelInterestRateChange} = useCommand()
    const document = useSelector(state => interestRateChange && interestRateChange.documentId && state.documents[interestRateChange.documentId])

    async function onSubmit() {
        const {waitForCommand} = await sendInterestRateChangeToDepositor(interestRateChange.id)
        const success = await waitForCommand()
        if (success) {
            navigate('/interestratechange-bank/' + interestRateChange.id)
        }
    }

    async function onSubmitCancel() {
        const {waitForCommand} = await cancelInterestRateChange(interestRateChange.id, '')
        const success = await waitForCommand()
        if (success) {
            navigate('/interestratechange-bank/')
        }
    }

    if (!document || !interestRateChange) return null

    return (
        <FxPaper title={t('notSentActionHeader')}>
            <p>
                {t('notSentInfo1', {date: DateOutput.formatDate(interestRateChange.sendDeadline)})} <HelpIcon
                supportCommonName="interestRateChange" inline={true}/>
            </p>
            <p>
                {t('notSentInfo2')}
            </p>
            <p className={styles.documentlink}>
                <DocumentIcon className={styles.icon}/>
                <a href={API_BASE_URL + document.link}>{t('documentName')}</a>
            </p>

            <div className={styles.buttonrow}>
                <Button variant={'secondary'} onClick={onSubmitCancel}>
                    {t('notSentDeleteButtonText')}
                </Button>

                <Button onClick={onSubmit} id="sendInterestChangeMessageToCustomerButton">
                    {t('notSentSendButtonText')}
                </Button>
            </div>
        </FxPaper>
    )
}
