import { PURPLE, SILVER_GRAY } from "#app/colors/colors";
import { Box, InputAdornment, Autocomplete, SxProps, TextField, Typography, Stack } from "@mui/material";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { countries, CountryType } from "./countryTypes";
import "flag-icons/css/flag-icons.min.css";
import { CountryCode } from "@fixrate/fixrate-query";

export default function CountrySelect({
    selectedCountry,
    setSelectedCountry,
    sx,
    disabled,
    dataCy
}: {
    selectedCountry: CountryCode | null,
    setSelectedCountry: (country: CountryCode | null) => void,
    sx?: SxProps,
    disabled?: boolean,
    dataCy?: string
}) {
    const { t } = useTranslation()
    const handleChange = (event: SyntheticEvent, country: CountryType | null) => {
        if (country) {
            setSelectedCountry(country.code)
        } else {
            setSelectedCountry(null)
        }
    }

    const sortedCountries = [...countries].sort((a, b) => {
        if (a.suggested && !b.suggested) {
            return -1;
        } else if (!a.suggested && b.suggested) {
            return 1;
        } else {
            return 0;
        }
    })

    return (
        <Autocomplete
            data-cy={dataCy ?? "country-select"}
            id="fixrate-country-select"
            disabled={disabled ? true : false}
            sx={sx}
            value={countries.find(country => country?.code === selectedCountry) || null}
            options={sortedCountries}
            fullWidth
            groupBy={(option) => (option.suggested ? t('components-CountrySelect.suggestions') : t('components-CountrySelect.all'))}
            autoComplete={false}
            getOptionLabel={(option: CountryType) => option.label}
            onChange={handleChange}
            renderGroup={(params) => (
                <Box key={params.key}>
                    <Typography
                        fontSize={"1.2rem"}
                        fontWeight={600}
                        sx={{
                            position: "sticky",
                            top: "-0.8rem",
                            py: 0.5,
                            px: 1,
                            backgroundColor: SILVER_GRAY[50],
                            color: PURPLE[900],
                        }}
                    >
                        {params.group}
                    </Typography>
                    <Box>{params.children}</Box>
                </Box>
            )}
            renderOption={(props, option) => {
                // Remove the key prop from the props object, necessary to avoid key being spread warning
                const p = { ...props }
                delete p["key"]
                return (
                    <li
                        data-cy={`country-select-option-${option.code}`}
                        {...p}
                        key={option.code}
                    >
                        <Stack direction="row">
                            <Box width={"2rem"} height={"2rem"} className={"fi fi-" + option.code.toLowerCase()} mr={1}/>
                            <span>{option.label} ({option.code})</span>
                        </Stack>
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            { selectedCountry && (
                                <InputAdornment position="start" sx={{ml: 1, mr: 1}}>
                                    <Box width={"2rem"} height={"2rem"} className={"fi fi-" + selectedCountry.toLowerCase()} />
                                </InputAdornment>
                            )}
                            {params.InputProps.startAdornment}
                          </>
                        )
                    }}
                />
            )}
        />
    );
}