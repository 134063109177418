import { useState } from 'react';
import {showConfirmationModal} from '#state/reducers/confirmationModal'
import {useDispatch} from 'react-redux'
import {useCommand} from '#command'
import styles from './OrderDetails.module.scss'
import {HelpIcon} from '#components'
import {useTranslation} from 'react-i18next'
import {OrderDto} from '@fixrate/fixrate-query'
import {Box, Button, Grid, MenuItem, Select, Stack, TextField} from '@mui/material'
import {PURPLE, SILVER_GRAY} from '#app/colors/colors'

export type Props = {
    order: OrderDto,
    closeModal: () => void
}

export default function OrderCancel({order, closeModal}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {cancelOrder} = useCommand()

    const [category, setCategory] = useState('')
    const [reason, setReason] = useState('')
    const [error, setError] = useState('')

    function onReasonChange(e) {
        setReason(e.target.value)
        setError('')
    }

    async function onSubmit() {
        if (!category) {
            setError(t('pages-orders.bankCancelProvideCategory'))
        } else if (!reason) {
            setError(t('pages-orders.bankCancelProvideReason'))
        } else {
            const {waitForCommand} = await cancelOrder(order.id, category, reason)
            const success = await waitForCommand()
            if (success) {
                dispatch(showConfirmationModal({
                    title: t('pages-orders.bankCanceledTitle'),
                    text: t('pages-orders.bankCanceledText'),
                    buttonText: t('common.close'),
                }))
                setError('')
                setReason('')
            }
        }
    }

    if (order.orderState === 'CANCELED' || order.orderState === 'COMPLETED') return null

    const isAbort = order.orderState !== 'INITIAL_PROCESSING'
    let instructions = t('pages-orders.bankRejectText')
    let buttonText = t('pages-orders.bankRejectButtonText')
    if (isAbort) {
        instructions = t('pages-orders.bankCancelText')
        buttonText = t('pages-orders.bankCancelButtonText')
    }

    return (
        <Stack spacing={2} className={styles.cancelModal}>
            <p>
                <span style={{marginRight: '0.2em'}}>{t('pages-orders.declineRule')}</span>
                <HelpIcon supportCommonName='terminationFloatingInterest' inlineText={t('pages-orders.readMoreAboutDeclining')} inline={true}/>
            </p>
            <Grid container direction={'row'}>
                <Grid item xs={2} sm={1}>
                    <StepIndicator step={1}/>
                </Grid>
                <Grid item xs={10} sm={11}>
                    <Stack spacing={1} maxWidth={'40rem'} pt={0.5}>
                        <label>{t('pages-orders.reasonForDeclining')}</label>
                        <Select
                            data-cy={'categoryReasonDropdown'}
                            value={category}
                            onChange={e => setCategory(e.target.value)}
                            fullWidth
                            displayEmpty
                        >
                            <MenuItem value={''} disabled>
                                <Box sx={{color: SILVER_GRAY[300]}}>{t('pages-orders.reasonForDeclining')}</Box>
                            </MenuItem>
                            <MenuItem value={'CUSTOMER_DECLARATION'}>{t('pages-orders.customerDeclaration')}</MenuItem>
                            <MenuItem value={'OVEREXPOSED'}>{t('pages-orders.overexposed')}</MenuItem>
                            <MenuItem value={'SEGMENT_NOT_ALLOWED'}>{t('pages-orders.notAllowedSegment')}</MenuItem>
                            <MenuItem value={'OTHER'}>{t('pages-orders.otherReasons')}</MenuItem>
                        </Select>
                        {category === 'CUSTOMER_DECLARATION' && (
                            <p>
                                <span>{t('pages-orders.customerDeclarationExplanation')} </span>
                                <a target="_blank" href="https://fixrate.no/kundeservice/kontakt-oss" rel="noreferrer">{t('pages-orders.contactSupport')}</a>
                            </p>
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Grid container direction={'row'}>
                <Grid item xs={2} sm={1}>
                    <StepIndicator step={2}/>
                </Grid>
                <Grid item xs={10} sm={11}>
                    <Stack spacing={1} maxWidth={'40rem'} pt={0.5}>
                        <label>{t('pages-orders.bankCancelReason')}</label>
                        <p>
                            <span>{instructions} </span>
                            <HelpIcon supportCommonName='termsBankAcceptingDeposits' inlineText={t('pages-orders.readMoreAboutDeclining')} inline={true}/>
                        </p>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            name="reason"
                            value={reason}
                            onChange={onReasonChange}
                            placeholder={t('pages-orders.bankCancelDescribeCause')}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {error && <p className="field-error-message">{error}</p>}
            <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                <Button onClick={closeModal} variant={'text'}>{t('pages-orders.goBack')}</Button>
                <Button
                    id="cancelOrderButton"
                    variant={'contained'}
                    onClick={onSubmit}
                    disabled={!category || !reason}
                >
                    {buttonText}
                </Button>
            </Stack>
        </Stack>
    )
}

function StepIndicator({step}: { step: number }) {
    return (
        <Box
            sx={{
                border: 'none',
                backgroundColor: PURPLE[50],
                color: PURPLE[500],
                fontWeight: 700,
                borderRadius: '100px',
                height: '3.4rem',
                width: '3.4rem',
                aspectRatio: '1/1',
                fontSize: '1.6rem',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {step}
        </Box>
    )
}
