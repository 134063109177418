import scrollToAnchor from '#app/scroll-to-anchor'
import { PersonValidator } from '#services/PersonValidator'
import {DepositorDto, OrganisationalPersonDto, PartnerAdvisorDto} from '@fixrate/fixrate-query'
import { Stack, Typography } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Person from './Person'
import Advisor, {AdvisorPerson} from "#pages/organizations/OrganizationDetail/UsersAndRoles/components/DepositorPersonList/Advisor";

type Props = {
    depositor: DepositorDto
}

export default function DepositorPersonList({ depositor }: Props) {
    const { t } = useTranslation()
    const basePath = `/organizations/${depositor.id}/users/all`

    scrollToAnchor()

    const sortFunction = (a, b) => a.firstName?.localeCompare(b.firstName)

    const isOnlyRoleSupport = (person: OrganisationalPersonDto) =>
        person.securityRoles.includes('DEPOSITOR_PARTNER_SUPPORT') &&
        (person.securityRoles.length === 1 || person.securityRoles.includes('DEPOSITOR_VIEW')) &&
        (!person.associatedInviteId) // If user is invited, he is about to be more than a support user

    const users: OrganisationalPersonDto[] = [
        ...depositor.people.filter(
            (person) => PersonValidator.isInUserList(person) && !isOnlyRoleSupport(person)
        ),
    ].sort(sortFunction)

    const advisors: AdvisorPerson[] = depositor?.partnerRelations
        ?.filter(relation => relation.partnerCategory !== 'SUPPORT')
        ?.flatMap(relation => relation.advisors.map(advisor => ({
            ...advisor,
            partnerCompanyName: relation.name
        })))
        ?.filter(advisor => !users.some(user => user.associatedUserId === advisor.userId))

    const otherPeople = depositor.people
        .filter((person) => !PersonValidator.isInUserList(person))
        .sort(sortFunction)

    return (
        <Stack spacing={4}>
            {users?.length > 0 && (
                <Stack spacing={2}>
                    <Typography variant="h2">
                        <i className='ri-account-circle-line' />
                        <span>{t('pages-organizations.usersWithAccess')}</span>
                    </Typography>
                    <AnimatePresence>
                        <Stack spacing={2}>
                            {users?.map((person) => (
                                <Person
                                    key={person.firstName + person.lastName}
                                    person={person as OrganisationalPersonDto}
                                    depositor={depositor}
                                    basePath={basePath}
                                />
                            ))}
                            {advisors?.map((advisor) => (
                                <Advisor advisor={advisor} key={advisor.userId}/>
                            ))}
                        </Stack>
                    </AnimatePresence>
                </Stack>
            )}

            {otherPeople?.length > 0 && (
                <Stack spacing={3}>
                    <Typography variant="h2">
                        <i className='ri-user-add-line' />
                        {t('pages-organizations.otherPeopleInOrganization')}
                    </Typography>
                    <AnimatePresence>
                        <Stack spacing={2}>
                            {otherPeople.map((person) => (
                                <Person
                                    key={person.personId}
                                    person={person}
                                    depositor={depositor}
                                />
                            ))}
                        </Stack>
                    </AnimatePresence>
                </Stack>
            )}
        </Stack>
    )
}
