import { loggedInToPayload } from '#app/services/payloadCMS/payloadCMS';
import { useQuery } from 'react-query';

function useIsLoggedInToPayload() : boolean {
    const {data: loggedIn} = useQuery({
        queryFn: async () => loggedInToPayload(),
        queryKey: ['logged-in-payload'],
    })

    return loggedIn || false
}

export { useIsLoggedInToPayload };
